import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Tooltip, Table, Divider, Button, Input, Radio, message, Modal, Menu, Tabs, Form, Spin, Badge, Select /* notification */ } from "antd";
import { SearchOutlined, ShoppingCartOutlined, CloseOutlined, ArrowLeftOutlined, CaretDownOutlined, EditFilled, LoadingOutlined, CloseSquareFilled, ExclamationCircleOutlined, /* CheckOutlined, MinusOutlined, PlusOutlined */ TagsOutlined } from "@ant-design/icons";
import { serverTokenUrl, serverUrl, edcUrl, innovitiPaymentCodes } from "../../constants/config";
import { clock, timeStamp, timeValidator, currentDay } from "../../utility/clock";
import { htmlPrint } from "../../lib/printer/htmlPrinter";
import { xmlPrint } from "../../lib/printer/xmlPrinter";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { useAuth } from "../../lib/auth";
import { v4 as uuidv4 } from "uuid";
import DefaultProductImage from "../../assets/images/default-product.webp";
import MenuIcon from "../../assets/images/menu.png";
import BarcodeReader from "react-barcode-reader";
import POSLogo from "../../assets/images/pos-logo.png";
import DropdownCircle from "../../assets/images/DropdownCircle.png";
import Quantity from "../../assets/images/qty.png";
import CancelOrder from "../../assets/images/cancel-order.png";
import IncrementIcon from "../../assets/images/plus.png";
import DecrementIcon from "../../assets/images/minus.png";
import DeleteCart from "../../assets/images/Iconly-Light-Delete.svg";
import Invoice from "../../assets/images/receipt.png";
import Home from "../../assets/images/dashboard.png";
import onHold from "../../assets/images/timer.png";
import FilterIcn from "../../assets/images/op.svg";
import AddProduct from "./AddProduct";
import ProductPanel from "./ProductPanel";
import QuantityUpdate from "./QuantityUpdate";
import Delete from "../../assets/images/delete.png";
import CashLogo from "../../assets/images/cash.png";
import CashLogoWhite from "../../assets/images/cashWhite.svg";
import Rupees from "../../assets/images/rupees.png";
import closeIcon from "../../assets/images/closeicon.png";
import Backspace from "../../assets/images/backspac.png";
import db from "../../database";
import Axios from "axios";
import "react-aspect-ratio/aspect-ratio.css";
import "../style.css";
import "../globalStyles.css";
// import Search from "antd/lib/input/Search";

// GLOBAL CONSTANTS START
const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item;
const TabPane = Tabs.TabPane;
const { Option } = Select;
// GLOBAL CONSTANTS END

// PointOfSale Component Start
const PointOfsale = () => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const defaultCustomer = JSON.parse(localStorage.getItem("defaultCustomer"));
  const tillDataPaymentMethods = tillData.tillAccess.csBunit.paymentMethodList;
  // const defaultPaymentMethodIndex = tillDataPaymentMethods.findIndex((data) => data.name === "Cash");
  // const defaultPaymentMethod = tillDataPaymentMethods[0];
  const tillDocumentSequence = parseFloat(localStorage.getItem("documentSequence"));
  const isPrintModeXML = tillData.tillAccess.cwrTill.hardwareController.imageUrl !== null ? true : false;
  const history = useHistory();
  const qs = require("querystring");
  const { setAuthTokens } = useAuth();

  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };

  // CLOCK BLOCK START
  const [displayClock, setDisplayClock] = useState(clock());
  useEffect(() => {
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  // CLOCK BLOCK END

  // ORDER TYPE BLOCK START
  const [orderType, setOrderType] = useState();
  const [posSaleTypes, setPosSaleTypes] = useState([]);
  const [displaySetOrderType, setDisplayOrderType] = useState(false);
  const changeOrderType = (type) => {
    setDisplayOrderType(false);
    setOrderType(type);
  };
  useEffect(() => {
    db.posSaletypes.toArray().then((saleType) => {
      setPosSaleTypes([...saleType]);
      const saleIndex = saleType.findIndex((st) => st.cwrSaletype.isdefault === "Y");
      setOrderType(saleType[saleIndex]);
    });
  }, []);
  // ORDER TYPE BLOCK END

  // CUSTOMER  SEARCH AND SELECTION BLOCK START
  const [displayCustomerSearch, setDisplayCustomerSearch] = useState(false);
  const [customerSearchType, setCustomerSearchType] = useState("mobile");
  const [customerSearchInput, setCustomerSearchInput] = useState("");
  const [customerSearchResults, setCustomerSearchResults] = useState();

  const closeCustomerSearch = () => {
    setDisplayCustomerSearch(false);
    setCustomerSearchInput("");
    setCustomerSearchResults();
  };

  const handleCustomerSearch = () => {
    let query = `mobileNo:"${customerSearchInput}"`;
    let validation = RegExp("^[6-9][0-9]{9}$");

    if (customerSearchType !== "mobile") {
      query = `searchKey:"${customerSearchInput}"`;
      validation = RegExp("^[0-9a-zA-Z]{1,20}$");
    }

    if (validation.test(parseFloat(customerSearchInput))) {
      const paramsInput = {
        query: `query{
          customerSearch(${query}){
            cwrCustomerId 
            code   
            name
            email        
            mobileNo
            name
            pincode
            retlLoyaltyBalance
            b2cRegisteredstoreId
             iscredit
            balancepoints
            loyaltyLevel{
                cwrLoyaltyLevelId
                name
                accumulationRate
                redemptionRate
            }
            sCustomer{
              sCustomerID
              customerCategory{
                sCustomerCateforyId
                value
                name
                description
              }
            }
            csBunit{
              csBunitId
              name
            }
          }
        }`,
      };
      Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${tokens.token_type} ${tokens.access_token}`,
        },
      })
        .then((response) => {
          if (response.data.data.customerSearch !== null && response.data.data.customerSearch.length > 0) {
            const result = response.data.data.customerSearch;
            setCustomerSearchResults(result);
          } else {
            console.warn("No customer found, try searching with different customer");
            setCustomerSearchResults([]);
          }
        })
        .catch((error) => {
          console.error("Customer search:", error);
          const err = JSON.parse(JSON.stringify(error));
          const { message } = err;
          if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
            const refToken = tokens.refresh_token;
            const requestBody = {
              grant_type: "refresh_token",
              refresh_token: refToken,
            };
            const config = {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Basic dGFsazJhbWFyZXN3YXJhbjpteS1zZWNyZXQ=",
              },
            };
            Axios.post(serverTokenUrl, qs.stringify(requestBody), config).then((newToken) => {
              setAuthTokens(newToken.data);
            });
          }
        });
    } else {
      console.warn("Invalid customer search input");
      message.warning("Invalid Customer Search Input");
    }
  };

  const selectCustomer = (index) => {
    setCart({
      ...cart,
      customer: customerSearchResults[index],
    });
    closeCustomerSearch();
  };
  // CUSTOMER  SEARCH AND SELECTION BLOCK END

  // ADD NEW CUSTOMER BLOCK START
  const [form] = Form.useForm();
  const [displayAddNewCustomer, setDisplayAddNewCustomer] = useState(false);

  const showAddNewCustomerFields = () => {
    setDisplayCustomerSearch(false);
    if (customerSearchType === "mobile") {
      form.setFieldsValue({
        mobile: customerSearchInput,
      });
    }
    setDisplayAddNewCustomer(true);
  };

  const addNewCustomer = (data) => {
    const checkMobNo = data.mobile;
    const paramsInput = {
      query: `query {
            customerSearch(mobileNo: "${checkMobNo}") {
              mobileNo          
            }
          }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    })
      .then((response) => {
        if (response.data.data.customerSearch.length === 0) {
          const newCustomerId = uuidv4().replace(/-/g, "").toUpperCase();
          const newRequest = {
            query: `mutation{
                  upsertB2CCustomer(id: "${newCustomerId}", type: "New",
                    customer:{
                      csBunitId:"0",
                      createdby:"${tillData.tillAccess.csUserId}",
                      updatedby:"${tillData.tillAccess.csUserId}",
                      code:"",
                      name:"${data.name}",
                      mobileNo:"${data.mobile}",
                      email:${data.email ? `${data.email}` : null},
                      pincode:${data.pincode ? data.pincode : null},   
                      sCustomerId:"${tillData.tillAccess.csBunit.cwrSCustomerId}"
                      finPaymentmethodId:null
                      retlLoyaltyBalance:null
                      iscredit:false
                  }){
                    cwrCustomerId
                    code
                    name
                    email
                    mobileNo
                    pincode
                    retlLoyaltyBalance
                    b2cRegisteredstoreId
                    sCustomer{
                      sCustomerID
                      customerCategory{
                        sCustomerCateforyId
                        value
                        name
                        description
                      }
                    }
                    csBunit{
                      csBunitId
                      name
                    }
                    status
                    message
                  }
                }`,
          };
          Axios({
            url: serverUrl,
            method: "POST",
            data: newRequest,
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${tokens.token_type} ${tokens.access_token}`,
            },
          })
            .then((response) => {
              const result = response.data.data.upsertB2CCustomer;
              if (result.status === "200") {
                form.resetFields();
                setDisplayAddNewCustomer(false);
                setCart({
                  ...cart,
                  customer: result,
                });
                closeCustomerSearch();
                message.success(result.message);
              } else {
                message.error("Some thing went wrong try again later !");
              }
            })
            .catch((error) => {
              console.error("Adding a new customer failed: ", error);
              message.error("Some thing went wrong try again later !");
            });
        }
      })
      .catch((error) => {
        console.error("Search for existnig customer failed: ", error);
        message.error("Some thing went wrong try again later !");
      });
  };
  // ADD NEW CUSTOMER BLOCK END

  // EDIT CUSTOMER BLOCK END
  const [displayEditOldCustomer, setDisplayEditOldCustomer] = useState(false);
  const [selectedEditOldCustomer, setSelectedEditOldCustomer] = useState();

  const showEditOldCustomerFields = (customer) => {
    setDisplayCustomerSearch(false);
    setSelectedEditOldCustomer(customer);
    form.setFieldsValue({
      editName: customer.name,
      editMobile: customer.mobileNo,
      editEmail: customer.email,
      editPincode: customer.pincode,
      name: "Name",
      mobile: 9999999999,
    });
    setDisplayEditOldCustomer(true);
  };

  const editOldCustomer = (data) => {
    const newRequest = {
      query: `mutation{
            upsertB2CCustomer(id: "${selectedEditOldCustomer.cwrCustomerId}", type:"Old",
              customer:{
                name: "${data.editName}"
                mobileNo: "${data.editMobile}"
                email: ${data.editEmail ? `${data.editEmail}` : null},
                pincode: ${data.editPincode ? data.editPincode : null},
            }){
              cwrCustomerId
              code
              name
              email
              mobileNo
              pincode
              retlLoyaltyBalance
              b2cRegisteredstoreId
              sCustomer{
                sCustomerID
                customerCategory{
                  sCustomerCateforyId
                  value
                  name
                  description
                }
              }
              csBunit{
                csBunitId
                name
              }
              status
              message
            }
          }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: newRequest,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    })
      .then((response) => {
        const result = response.data.data.upsertB2CCustomer;
        if (result.status === "200") {
          form.resetFields();
          setSelectedEditOldCustomer();
          setDisplayEditOldCustomer(false);
          setCart({
            ...cart,
            customer: result,
          });
          closeCustomerSearch();
          message.success(result.message);
        } else {
          message.error("Some thing went wrong try again later !");
        }
      })
      .catch((error) => {
        console.error("Updating customer failed: ", error);
        message.error("Some thing went wrong try again later !");
      });
  };
  // EDIT CUSTOMER BLOCK END

  //// CENTER BUTTON BLOCK START /////

  const [isQtyUpdate, setIsQtyUpdate] = useState(false);

  const [selectedProductInCart, setSelectedProductInCart] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const selectProductInCart = (record) => {
    setSelectedRowKeys([record.key]);
    setSelectedProductInCart(record);
  };

  const deleteProduct = (addToCart) => {
    addProduct(addToCart, -addToCart.weight);
  };

  const decreaseProductQty = (addToCart) => {
    if (!addToCart.isManualQty) {
      addProduct(addToCart, -1);
    }
  };

  const increaseProductQty = (addToCart) => {
    if (!addToCart.isManualQty) {
      addProduct(addToCart, 1);
    }
  };

  const deleteReceipt = () => {
    setCart({
      items: [],
      total: 0,
      tax: 0,
      discount: 0,
      paid: 0,
      change: 0,
      totalQty: 0,
      roundOff: 0,
      payments: [],
      redemptionPoints: 0,
      accumulationPoints: 0,
      creditAmount: 0,
      sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
      customer: defaultCustomer,
    });
    setSelectedRowKeys([]);
    setSelectedProductInCart({});
    setAmount("");
    setSelectedPaymentMethod("");
  };

  const deleteCart = () => {
    deleteReceipt();
    if (parkedList.length > 0) {
      selectParkedBill(parkedList[0]);
    }
  };

  // ORDER HISTORY BLOCK START
  const [displayOrderHistory, setDisplayOrderHistory] = useState(false);
  const [orderHistoryDetails, setOrderHistoryDetails] = useState([]);

  const [orderHistorySearchType, setOrderHistorySearchType] = useState("orderDocumentNo");
  const changeOrderHistorySearchType = (value) => {
    setOrderHistorySearchType(value);
  };

  const showOrderHistory = () => {
    db.orders
      .orderBy("orderTime")
      .limit(20)
      .reverse()
      .toArray()
      .then((data) => {
        setOrderHistoryDetails([...data]);
        setDisplayOrderHistory(true);
      });
  };

  const [selectedOrderHistoryLine, setSelectedOrderHistoryLine] = useState("");
  const showOrderHistoryLine = (orderID) => {
    if (selectedOrderHistoryLine === orderID) {
      setSelectedOrderHistoryLine("");
    } else {
      setSelectedOrderHistoryLine(orderID);
    }
  };

  const [orderHistoryInput, setOrderHistoryInput] = useState("");
  const searchOrderHistory = () => {
    let indexType = "documentno";
    if (orderHistorySearchType === "orderDocumentNo") {
      indexType = "documentno";
    }

    if (orderHistorySearchType === "orderCustomerSearchKey") {
      indexType = "customerSearchKey";
    }

    db.orders
      .where(indexType)
      .startsWithIgnoreCase(orderHistoryInput)
      .limit(20)
      .toArray()
      .then((data) => {
        setOrderHistoryDetails([...data]);
      });
    setOrderHistoryInput("");
  };

  // ORDER HISTORY BLOCK END

  // PARKED BILL BLOCK START
  const storedParkedList = JSON.parse(localStorage.getItem("parkedList"));
  const initialParkedList = storedParkedList ? storedParkedList : [];
  const [displayParkedBillModal, setDisplayParkedBillModal] = useState(false);
  const [parkedList, setParkedList] = useState(initialParkedList);
  const [filterdParkedList, setFilterdParkedList] = useState(initialParkedList);

  useEffect(() => {
    setFilterdParkedList(parkedList);
  }, [parkedList]);

  const openDisplayParkedBillModal = () => {
    if (cart.items.length > 0) {
      Modal.confirm({
        title: "Save Cart Items ?",
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            Items in cart might lost.
            <br />
            Please save in order to retrive later
          </div>
        ),
        okText: "Save",
        cancelText: "No",
        onOk() {
          parkBill();
          // message.info("Please wait...");
          setTimeout(() => {
            setDisplayParkedBillModal(true);
          }, 2000);
        },
        onCancel() {
          setDisplayParkedBillModal(true);
        },
      });
    } else {
      setDisplayParkedBillModal(true);
    }
  };

  const parkBill = () => {
    const presentParkedList = parkedList;
    const presentCart = cart;
    const presentTimeStamp = timeStamp();

    const parkedBill = {
      parkedCart: presentCart,
      parkedTime: presentTimeStamp,
      parkedBillId: uuidv4().replace(/-/g, "").toUpperCase(),
    };
    presentParkedList.push(parkedBill);
    localStorage.setItem("parkedList", JSON.stringify(presentParkedList));
    setParkedList([...presentParkedList]);
    deleteReceipt();
    message.success("Bill Parked Successfully");
  };

  const selectParkedBill = (item) => {
    const listItemIndex = parkedList.findIndex((bill) => bill.parkedBillId === item.parkedBillId);
    const selectedParkedBill = parkedList[listItemIndex].parkedCart;
    const presentParkedList = parkedList;
    presentParkedList.splice(listItemIndex, 1);
    localStorage.setItem("parkedList", JSON.stringify(presentParkedList));
    setParkedList([...presentParkedList]);
    setCart(selectedParkedBill);
    setDisplayParkedBillModal(false);
  };

  const [parkedBillSearchInput, setParkedBillSearchInput] = useState("");

  const handleParkedBillSearchInput = (e) => {
    setParkedBillSearchInput(e.target.value);
  };

  const searchParkedBill = () => {
    const serchResults = parkedList.filter((list) => list.parkedCart.customer.code.toLowerCase() === parkedBillSearchInput.toLowerCase());
    if (parkedBillSearchInput.length > 1) {
      setFilterdParkedList([...serchResults]);
    } else {
      setFilterdParkedList([...parkedList]);
    }
    setParkedBillSearchInput("");
  };

  const closeParkedBillModal = () => {
    setDisplayParkedBillModal(false);
    setParkedBillSearchInput("");
    setFilterdParkedList([...parkedList]);
  };

  // PARKED BILL BLOCK END

  //// CENTER BUTTON BLOCK END ////

  //// CART OPERATIONS START ////

  // DEFAULT CART START
  const [cart, setCart] = useState({
    items: [],
    total: 0,
    tax: 0,
    discount: 0,
    paid: 0,
    change: 0,
    totalQty: 0,
    roundOff: 0,
    payments: [],
    redemptionPoints: 0,
    accumulationPoints: 0,
    creditAmount: 0,
    sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
    customer: defaultCustomer,
  });
  // DEFAULT CART END

  // BARCODE READER BLOCK START
  const [displayBatchSelection, setDisplayBatchSelection] = useState(false);
  const [batchSetAvailable, setBatchSetAvailable] = useState([]);

  const onBarcodeInput = (data) => {
    db.products
      .where("upcIndex")
      .equalsIgnoreCase(data)
      .toArray()
      .then((product) => {
        if (product.length > 0) {
          const obj = product[0];
          if (obj.batchedProduct === "Y") {
            if (obj.mBatch.length === 1) {
              addDefinedProduct(obj, obj.mBatch[0].upc, obj.mBatch[0].batchno, obj.mBatch[0].mBatchId, obj.mBatch[0].price);
            } else {
              // let upcKeys = 0;
              const productSet = [];
              for (let i = 0; i < obj.mBatch.length; i += 1) {
                const batchItem = obj.mBatch[i];
                const productDefined = {
                  batchno: batchItem.batchno,
                  description: obj.description,
                  discount: 0,
                  discountName: "",
                  imageurl: obj.imageurl,
                  isDecimal: obj.isDecimal,
                  isManualQty: obj.isManualQty,
                  isPromoApplicable: obj.isPromoApplicable,
                  isReturn: false,
                  mBatchId: batchItem.mBatchId,
                  mPricingruleId: null,
                  name: obj.name,
                  nettotal: 0,
                  primaryOrderLine: null,
                  productId: obj.mProductId,
                  realPrice: batchItem.price,
                  returnQty: null,
                  salePrice: batchItem.price,
                  stock: obj.onhandQty,
                  tax: obj.cTaxId,
                  taxAmount: 0,
                  taxRate: obj.taxRate,
                  uom: obj.csUomId,
                  uom_name: obj.uomName,
                  upc: batchItem.upc,
                  value: obj.value,
                  weight: 0,
                  shortDescription: obj.shortDescription,
                  hsncode: obj.hsncode,
                  csBunitId: obj.csBunitId,
                  mProductCategoryId: obj.mProductCategoryId,
                  productManufacturerId: obj.productManufacturerId,
                  productBrandId: obj.productBrandId,
                };
                // if (batchItem.upc.toLowerCase() === data.toLowerCase()) upcKeys += 1;
                productSet.push(productDefined);
              }
              setBatchSetAvailable([...productSet]);
              setDisplayBatchSelection(true);
            }
          } else {
            addDefinedProduct(obj, obj.upc, null, null, obj.sunitprice);
          }
        } else {
          message.warning("Product Not Found !");
        }
      });
  };

  const addDefinedProduct = (productObj, upc, batchno, mBatchId, price) => {
    const productDefined = {
      batchno: batchno,
      description: productObj.description,
      discount: 0,
      discountName: "",
      imageurl: productObj.imageurl,
      isDecimal: productObj.isDecimal,
      isManualQty: productObj.isManualQty,
      isPromoApplicable: productObj.isPromoApplicable,
      isReturn: false,
      mBatchId: mBatchId,
      mPricingruleId: null,
      name: productObj.name,
      nettotal: 0,
      primaryOrderLine: null,
      productId: productObj.mProductId,
      realPrice: price,
      returnQty: null,
      salePrice: price,
      stock: productObj.onhandQty,
      tax: productObj.cTaxId,
      taxAmount: 0,
      taxRate: productObj.taxRate,
      uom: productObj.csUomId,
      uom_name: productObj.uomName,
      upc: upc,
      value: productObj.value,
      weight: 0,
      shortDescription: productObj.shortDescription,
      hsncode: productObj.hsncode,
      csBunitId: productObj.csBunitId,
      mProductCategoryId: productObj.mProductCategoryId,
      productManufacturerId: productObj.productManufacturerId,
      productBrandId: productObj.productBrandId,
    };
    checkIsManualWeight(productDefined);
  };

  const selectProductToCart = (data) => {
    checkIsManualWeight(data);
    setDisplayBatchSelection(false);
  };

  const onBarcodeInputError = (data) => {
    console.error("Barcode input error: ", data);
  };
  // BARCODE READER BLOCK END

  // PRODUCT WEIGHT MODAL START
  const [displayManualQtyWeightInput, setDisplayManualQtyWeightInput] = useState(false);
  const [productWeightModalInput, setProductWeightModalInput] = useState("");
  const [currentWeightSelectedProduct, setCurrentWeightSelectedProduct] = useState({});

  const onProductModalChangeWeight = (event) => {
    setProductWeightModalInput(event.target.value);
  };

  const checkIsManualWeight = (prod) => {
    if (prod.isManualQty) {
      setDisplayManualQtyWeightInput(true);
      setCurrentWeightSelectedProduct(prod);
    } else {
      addProduct(prod, 1);
    }
  };

  const addManualWeightToProduct = () => {
    let inputValidator = new RegExp(/^[0-9]*$/);
    if (currentWeightSelectedProduct.isDecimal) inputValidator = new RegExp(/^[0-9]\d*(\.\d+)?$/);
    if (inputValidator.test(productWeightModalInput) && parseFloat(productWeightModalInput) > 0) {
      setDisplayManualQtyWeightInput(false);
      addProduct(currentWeightSelectedProduct, productWeightModalInput);
      setTimeout(() => {
        setProductWeightModalInput("");
        setCurrentWeightSelectedProduct({});
      }, 500);
    } else {
      console.warn("Invalid weight input: ", productWeightModalInput);
      message.warning("Invalid input value");
    }
  };

  // PRODUCT WEIGHT MODAL END

  // PRODUCT FILTER START
  const [isProductsFilter, setIsProductsFilter] = useState(false);
  const [productCategories, setProductCategories] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState("allProducts");
  const [productsList, setProductsList] = useState([]);

  useEffect(() => {
    db.productCategories.toArray().then((res) => {
      setProductCategories([...res]);
    });
  }, []);

  useEffect(() => {
    getCategoryProducts(selectedProductCategory);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const selectProductCategory = (category) => {
    if (selectedProductCategory !== category) {
      setSelectedProductCategory(category);
      setProductsList([]);
    }
  };

  useEffect(() => {
    getCategoryProducts();
  }, [selectedProductCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  const getMoreProducts = () => {
    if (productSearchInput === "") {
      getCategoryProducts(selectedProductCategory);
    }
  };

  const getCategoryProducts = () => {
    if (selectedProductCategory === "allProducts") {
      db.products
        .offset(productsList.length)
        .limit(100)
        .toArray()
        .then((productsFetched) => {
          const additionalProductsFetched = productsList.concat(productsFetched);
          setProductsList([...additionalProductsFetched]);
        });
    } else {
      db.products
        .where("mProductCategoryId")
        .equalsIgnoreCase(selectedProductCategory)
        .offset(productsList.length)
        .limit(100)
        .toArray()
        .then((productsFetched) => {
          const additionalProductsFetched = productsList.concat(productsFetched);
          setProductsList([...additionalProductsFetched]);
        });
    }
  };
  // PRODUCT FILTER END

  // PRODUCT SEARCH START
  const [isSearchProducts, setIsSearchProducts] = useState(false);
  const [productSearchInput, setProductSearchInput] = useState("");

  useEffect(() => {
    if (productSearchInput !== "" && productSearchInput.length >= 3) {
      getSearchedProducts();
    }
  }, [productSearchInput]); // eslint-disable-line

  const getSearchedProducts = () => {
    db.products
      .where("name")
      .startsWithIgnoreCase(productSearchInput)
      .or("batchIndex")
      .equalsIgnoreCase(productSearchInput)
      .or("upcIndex")
      .equalsIgnoreCase(productSearchInput)
      .or("value")
      .equalsIgnoreCase(productSearchInput)
      .limit(100)
      .toArray()
      .then((productsFetched) => {
        setProductsList([...productsFetched]);
      });
  };

  const clearProductSearchResults = () => {
    setProductSearchInput("");
    setProductsList([]);
    if (selectedProductCategory !== "allProducts") {
      setSelectedProductCategory("allProducts");
    } else {
      setSelectedProductCategory(`${productCategories[0].mProductCategoryId}`);
    }
  };

  const productSearchInputRef = useRef(null);

  const closeProductPanel = () => {
    setIsSearchProducts(false);
    setIsProductsFilter(false);
    setProductSearchInput("");
    clearProductSearchResults();
    productSearchInputRef.current.blur();
  };
  // PRODUCT SEARCH END

  const addProduct = (addToCart, qty) => {
    const weight = parseFloat(qty);
    const addedToCart = cart.items;
    const index = addedToCart.findIndex((p) => p.productId === addToCart.productId && p.upc === addToCart.upc && p.mBatchId === addToCart.mBatchId);
    if (index >= 0) {
      if (weight > 0) {
        message.success(`${addedToCart[index].name} Added Successfully`);
      }
      addedToCart[index].weight = addedToCart[index].weight + weight;
      if (addedToCart[index].weight === 0) {
        addedToCart.splice(index, 1);
      } else {
        const mrp = parseFloat(addedToCart[index].salePrice) * addedToCart[index].weight;
        const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
        addedToCart[index].taxAmount = tax;
        addedToCart[index].nettotal = mrp;
        const presentProduct = addedToCart[index];
        addedToCart.splice(index, 1);
        addedToCart.unshift(presentProduct);
        addedToCart.reverse();
      }
    } else {
      if (weight !== 0) {
        addToCart.weight = weight;
        const mrp = parseFloat(addToCart.salePrice) * addToCart.weight;
        const tax = mrp - mrp / (1 + addToCart.taxRate / 100);
        addToCart.taxAmount = tax;
        addToCart.nettotal = mrp;
        addedToCart.push(addToCart);
      }
    }

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    let totalDiscounts = 0;
    for (let i = 0; i < addedToCart.length; i += 1) {
      totalPrice += addedToCart[i].nettotal;
      totalItemsQty += addedToCart[i].weight;
      totalTax += addedToCart[i].taxAmount;
      totalDiscounts += addedToCart[i].discount;
      addedToCart[i].key = i;
    }

    addedToCart.reverse();

    const roundOffValue = Math.round(totalPrice);
    const totalRoundOff = totalPrice - roundOffValue;
    totalPrice = roundOffValue;

    setCart({
      ...cart,
      items: [...addedToCart],
      total: totalPrice,
      tax: totalTax,
      discount: totalDiscounts,
      totalQty: totalItemsQty,
      roundOff: totalRoundOff,
    });
    setSelectedRowKeys([]);
    setSelectedProductInCart({});
    checkProductDiscounts(addToCart);
  };

  const checkProductDiscounts = (product) => {
    if (product.isPromoApplicable) {
      db.pricingRules.toArray().then((pr) => {
        const pricingRuleCount = pr.length;
        if (pricingRuleCount > 0) {
          for (let i = 0; i < pricingRuleCount; i++) {
            const pricingRule = pr[i];
            if (timeValidator(pricingRule.startDate, pricingRule.endDate)) {
              if (pricingRule.timeSpecific === "Y") {
                const pStartTime = pricingRule.starttime.substring(11);
                const pEndTIme = pricingRule.endtime.substring(11);
                const starttime = timeStamp().substring(0, 10) + " " + pStartTime;
                const endtime = timeStamp().substring(0, 10) + " " + pEndTIme;
                const weekDay = currentDay();
                if (timeValidator(starttime, endtime) && pricingRule[weekDay] === "Y") {
                  processProductDiscounts(product, pricingRule);
                }
              } else {
                processProductDiscounts(product, pricingRule);
              }
            }
          }
        }
      });
    }
  };

  const processProductDiscounts = (product, pricingRule) => {
    if (pricingRule.type === "FD" && pricingRule.iscoupon === "N") {
      const saleTypeValidation = pricingRule.cwrSaletypeId === null ? true : pricingRule.cwrSaletypeId === orderType.cwrSaletype.cwrSaletypeId;
      let discountValidator = true;

      if (pricingRule.mPricingCcategories.length > 0) {
        discountValidator = pricingRule.mPricingCcategories.some((pc) => pc.mPricingCcategoryId === cart.customer.sCustomer.customerCategory.sCustomerCateforyId);
      }

      if (pricingRule.mPricingPcategories.length > 0) {
        discountValidator = pricingRule.mPricingPcategories.some((pc) => pc.mPricingPcategoryId === product.mProductCategoryId);
      }

      if (pricingRule.mPricingBrands.length > 0) {
        discountValidator = pricingRule.mPricingBrands.some((pc) => pc.mPricingBrandId === product.productBrandId);
      }

      if (pricingRule.mPricingBUnits.length > 0) {
        discountValidator = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
      }

      if (saleTypeValidation && discountValidator) {
        const addedToCart = cart.items;
        const index = addedToCart.findIndex((p) => p.productId === product.productId && p.upc === product.upc && p.mBatchId === product.mBatchId);
        const offerIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === product.productId);
        if (offerIndex >= 0 && index >= 0) {
          if (addedToCart[index].weight >= pricingRule.minimumQty) {
            const discountAmt = pricingRule.amountDiscount;
            const sp = parseFloat(addedToCart[index].realPrice) - discountAmt;
            const mrp = parseFloat(sp) * addedToCart[index].weight;
            const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
            addedToCart[index].salePrice = sp;
            addedToCart[index].taxAmount = tax;
            addedToCart[index].nettotal = mrp;
            addedToCart[index].discount = discountAmt * addedToCart[index].weight;
            addedToCart[index].discountName = pricingRule.printedName;
            addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;
            addedToCart[index].nameOriginal = addedToCart[index].nameOriginal ? addedToCart[index].nameOriginal : addedToCart[index].name;
            addedToCart[index].name = addedToCart[index].nameOriginal + "  " + pricingRule.printedName;
            // message.success(pricingRule.printedName);
          } else {
            const mrp = parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight;
            const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
            addedToCart[index].taxAmount = tax;
            addedToCart[index].nettotal = mrp;
            addedToCart[index].discount = 0;
            addedToCart[index].discountName = "";
            addedToCart[index].mPricingruleId = null;
            delete addedToCart[index].nameOriginal;
          }

          let totalTax = 0;
          let totalPrice = 0;
          let totalItemsQty = 0;
          let totalDiscounts = 0;
          for (let i = 0; i < addedToCart.length; i += 1) {
            totalPrice += addedToCart[i].nettotal;
            totalItemsQty += addedToCart[i].weight;
            totalTax += addedToCart[i].taxAmount;
            totalDiscounts += addedToCart[i].discount;
            addedToCart[i].key = i;
          }

          const roundOffValue = Math.round(totalPrice);
          const totalRoundOff = totalPrice - roundOffValue;
          totalPrice = roundOffValue;

          setCart({
            ...cart,
            items: [...addedToCart],
            total: totalPrice,
            tax: totalTax,
            discount: totalDiscounts,
            totalQty: totalItemsQty,
            roundOff: totalRoundOff,
          });
        }
      }
    }

    if (pricingRule.type === "PD" && pricingRule.iscoupon === "N") {
      const saleTypeValidation = pricingRule.cwrSaletypeId === null ? true : pricingRule.cwrSaletypeId === orderType.cwrSaletype.cwrSaletypeId;
      let discountValidator = true;

      if (pricingRule.mPricingCcategories.length > 0) {
        discountValidator = pricingRule.mPricingCcategories.some((pc) => pc.mPricingCcategoryId === cart.customer.sCustomer.customerCategory.sCustomerCateforyId);
      }

      if (pricingRule.mPricingPcategories.length > 0) {
        discountValidator = pricingRule.mPricingPcategories.some((pc) => pc.mPricingPcategoryId === product.mProductCategoryId);
      }

      if (pricingRule.mPricingBrands.length > 0) {
        discountValidator = pricingRule.mPricingBrands.some((pc) => pc.mPricingBrandId === product.productBrandId);
      }

      if (pricingRule.mPricingBUnits.length > 0) {
        discountValidator = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
      }

      if (saleTypeValidation && discountValidator) {
        const addedToCart = cart.items;
        const index = addedToCart.findIndex((p) => p.productId === product.productId && p.upc === product.upc && p.mBatchId === product.mBatchId);
        const offerIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === product.productId);
        if (offerIndex >= 0 && index >= 0) {
          if (addedToCart[index].weight >= pricingRule.minimumQty) {
            const discountAmt = (pricingRule.percentageDiscount / 100) * parseFloat(addedToCart[index].realPrice);
            const sp = parseFloat(addedToCart[index].realPrice) - discountAmt;
            const mrp = parseFloat(sp) * addedToCart[index].weight;
            const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
            addedToCart[index].salePrice = sp;
            addedToCart[index].taxAmount = tax;
            addedToCart[index].nettotal = mrp;
            addedToCart[index].discount = discountAmt * addedToCart[index].weight;
            addedToCart[index].discountName = pricingRule.printedName;
            addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;
            addedToCart[index].nameOriginal = addedToCart[index].nameOriginal ? addedToCart[index].nameOriginal : addedToCart[index].name;
            addedToCart[index].name = addedToCart[index].nameOriginal + "  " + pricingRule.printedName;
            // message.success(pricingRule.printedName);
          } else {
            const mrp = parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight;
            const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
            addedToCart[index].taxAmount = tax;
            addedToCart[index].nettotal = mrp;
            addedToCart[index].discount = 0;
            addedToCart[index].discountName = "";
            addedToCart[index].mPricingruleId = null;
            delete addedToCart[index].nameOriginal;
          }

          let totalTax = 0;
          let totalPrice = 0;
          let totalItemsQty = 0;
          let totalDiscounts = 0;
          for (let i = 0; i < addedToCart.length; i += 1) {
            totalPrice += addedToCart[i].nettotal;
            totalItemsQty += addedToCart[i].weight;
            totalTax += addedToCart[i].taxAmount;
            totalDiscounts += addedToCart[i].discount;
            addedToCart[i].key = i;
          }

          const roundOffValue = Math.round(totalPrice);
          const totalRoundOff = totalPrice - roundOffValue;
          totalPrice = roundOffValue;

          setCart({
            ...cart,
            items: [...addedToCart],
            total: totalPrice,
            tax: totalTax,
            discount: totalDiscounts,
            totalQty: totalItemsQty,
            roundOff: totalRoundOff,
          });
        }
      }
    }

    if (pricingRule.type === "QD" && pricingRule.iscoupon === "N") {
      const saleTypeValidation = pricingRule.cwrSaletypeId === null ? true : pricingRule.cwrSaletypeId === orderType.cwrSaletype.cwrSaletypeId;
      let discountValidator = true;

      if (pricingRule.mPricingCcategories.length > 0) {
        discountValidator = pricingRule.mPricingCcategories.some((pc) => pc.mPricingCcategoryId === cart.customer.sCustomer.customerCategory.sCustomerCateforyId);
      }

      if (pricingRule.mPricingPcategories.length > 0) {
        discountValidator = pricingRule.mPricingPcategories.some((pc) => pc.mPricingPcategoryId === product.mProductCategoryId);
      }

      if (pricingRule.mPricingBrands.length > 0) {
        discountValidator = pricingRule.mPricingBrands.some((pc) => pc.mPricingBrandId === product.productBrandId);
      }

      if (pricingRule.mPricingBUnits.length > 0) {
        discountValidator = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
      }

      if (saleTypeValidation && discountValidator) {
        const addedToCart = cart.items;
        const index = addedToCart.findIndex((p) => p.productId === product.productId && p.upc === product.upc && p.mBatchId === product.mBatchId);
        const offerIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === product.productId);
        if (offerIndex >= 0 && index >= 0) {
          let discountAmt = 0;
          if (pricingRule.mPricingQuantities.length >= 0) {
            pricingRule.mPricingQuantities.map((qd) => {
              if (parseFloat(qd.quantity) === parseFloat(addedToCart[index].weight)) {
                if (pricingRule.discountType === "V") {
                  discountAmt = parseFloat(qd.discountValue);
                } else {
                  discountAmt = (qd.discountValue / 100) * parseFloat(addedToCart[index].realPrice);
                }
              }
              return null;
            });
          }

          if (discountAmt > 0) {
            const sp = (parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight - discountAmt) / addedToCart[index].weight;
            const mrp = parseFloat(sp) * addedToCart[index].weight;
            const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
            addedToCart[index].salePrice = sp;
            addedToCart[index].taxAmount = tax;
            addedToCart[index].nettotal = mrp;
            addedToCart[index].discount = discountAmt * addedToCart[index].weight;
            addedToCart[index].discountName = pricingRule.printedName;
            addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;
            addedToCart[index].nameOriginal = addedToCart[index].nameOriginal ? addedToCart[index].nameOriginal : addedToCart[index].name;
            addedToCart[index].name = addedToCart[index].nameOriginal + "  " + pricingRule.printedName;
            // message.success(pricingRule.printedName);
          } else {
            const mrp = parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight;
            const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
            addedToCart[index].taxAmount = tax;
            addedToCart[index].nettotal = mrp;
            addedToCart[index].discount = 0;
            addedToCart[index].discountName = "";
            addedToCart[index].mPricingruleId = null;
            delete addedToCart[index].nameOriginal;
          }

          let totalTax = 0;
          let totalPrice = 0;
          let totalItemsQty = 0;
          let totalDiscounts = 0;
          for (let i = 0; i < addedToCart.length; i += 1) {
            totalPrice += addedToCart[i].nettotal;
            totalItemsQty += addedToCart[i].weight;
            totalTax += addedToCart[i].taxAmount;
            totalDiscounts += addedToCart[i].discount;
            addedToCart[i].key = i;
          }

          const roundOffValue = Math.round(totalPrice);
          const totalRoundOff = totalPrice - roundOffValue;
          totalPrice = roundOffValue;

          setCart({
            ...cart,
            items: [...addedToCart],
            total: totalPrice,
            tax: totalTax,
            discount: totalDiscounts,
            totalQty: totalItemsQty,
            roundOff: totalRoundOff,
          });
        }
      }
    }

    if (pricingRule.type === "PK" && pricingRule.iscoupon === "N") {
      const saleTypeValidation = pricingRule.cwrSaletypeId === null ? true : pricingRule.cwrSaletypeId === orderType.cwrSaletypeId;
      let discountValidator = true;

      if (pricingRule.mPricingCcategories.length > 0) {
        discountValidator = pricingRule.mPricingCcategories.some((pc) => pc.mPricingCcategoryId === cart.customer.sCustomer.customerCategory.sCustomerCateforyId);
      }

      if (pricingRule.mPricingPcategories.length > 0) {
        discountValidator = pricingRule.mPricingPcategories.some((pc) => pc.mPricingPcategoryId === product.mProductCategoryId);
      }

      if (pricingRule.mPricingBrands.length > 0) {
        discountValidator = pricingRule.mPricingBrands.some((pc) => pc.mPricingBrandId === product.productBrandId);
      }

      if (pricingRule.mPricingBUnits.length > 0) {
        discountValidator = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
      }

      if (saleTypeValidation & discountValidator) {
        const addedToCart = cart.items;
        const offerProducts = pricingRule.mPricingXProducts;
        const prodIndexes = [];
        const properWeights = [];
        let productsPriceTotal = 0;

        addedToCart.map((item, index) => {
          const ifExistis = offerProducts.findIndex((op) => op.mProductId === item.productId);
          if (ifExistis >= 0) {
            prodIndexes.push(index);
            properWeights.push(item.weight);
            productsPriceTotal += parseFloat(item.realPrice);
          }
          return null;
        });

        if (prodIndexes.length === offerProducts.length) {
          const distributedAmount = parseFloat(pricingRule.fixedUnitPrice) / prodIndexes.length;
          const distributedDiscount = (productsPriceTotal - distributedAmount) / prodIndexes.length;
          if (properWeights.every((item) => item === properWeights[0])) {
            prodIndexes.map((index) => {
              const sp = distributedAmount;
              const mrp = parseFloat(sp) * addedToCart[index].weight;
              const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
              addedToCart[index].salePrice = sp;
              addedToCart[index].taxAmount = tax;
              addedToCart[index].nettotal = mrp;
              addedToCart[index].discount = distributedDiscount;
              addedToCart[index].discountName = pricingRule.printedName;
              addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;
              addedToCart[index].nameOriginal = addedToCart[index].nameOriginal ? addedToCart[index].nameOriginal : addedToCart[index].name;
              addedToCart[index].name = addedToCart[index].nameOriginal + "  " + pricingRule.printedName;
              // message.success(pricingRule.printedName);
              return null;
            });
          } else {
            prodIndexes.map((index) => {
              const mrp = parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight;
              const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
              addedToCart[index].taxAmount = tax;
              addedToCart[index].nettotal = mrp;
              addedToCart[index].discount = 0;
              addedToCart[index].discountName = "";
              addedToCart[index].mPricingruleId = null;
              delete addedToCart[index].nameOriginal;
              return null;
            });
          }
        } else {
          prodIndexes.map((index) => {
            const mrp = parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight;
            const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
            addedToCart[index].taxAmount = tax;
            addedToCart[index].nettotal = mrp;
            addedToCart[index].discount = 0;
            addedToCart[index].discountName = "";
            addedToCart[index].mPricingruleId = null;
            delete addedToCart[index].nameOriginal;
            return null;
          });
        }

        let totalTax = 0;
        let totalPrice = 0;
        let totalItemsQty = 0;
        let totalDiscounts = 0;
        for (let i = 0; i < addedToCart.length; i += 1) {
          totalPrice += addedToCart[i].nettotal;
          totalItemsQty += addedToCart[i].weight;
          totalTax += addedToCart[i].taxAmount;
          totalDiscounts += addedToCart[i].discount;
          addedToCart[i].key = i;
        }

        const roundOffValue = Math.round(totalPrice);
        const totalRoundOff = totalPrice - roundOffValue;
        totalPrice = roundOffValue;

        setCart({
          ...cart,
          items: [...addedToCart],
          total: totalPrice,
          tax: totalTax,
          discount: totalDiscounts,
          totalQty: totalItemsQty,
          roundOff: totalRoundOff,
        });
      }
    }

    if (pricingRule.type === "XGY" && pricingRule.iscoupon === "N") {
      const saleTypeValidation = pricingRule.cwrSaletypeId === null ? true : pricingRule.cwrSaletypeId === orderType.cwrSaletypeId;
      let discountValidator = true;

      if (pricingRule.mPricingCcategories.length >= 0) {
        discountValidator = pricingRule.mPricingCcategories.some((pc) => pc.mPricingCcategoryId === cart.customer.sCustomer.customerCategory.sCustomerCateforyId);
      }

      if (pricingRule.mPricingPcategories.length >= 0) {
        return (discountValidator = pricingRule.mPricingPcategories.some((pc) => pc.mPricingPcategoryId === product.mProductCategoryId));
      }

      if (pricingRule.mPricingBrands.length >= 0) {
        return (discountValidator = pricingRule.mPricingBrands.some((pc) => pc.mPricingBrandId === product.productBrandId));
      }

      if (pricingRule.mPricingBUnits.length >= 0) {
        return (discountValidator = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId));
      }

      if (saleTypeValidation && discountValidator) {
        const addedToCart = cart.items;
        const offerProducts = pricingRule.mPricingXProducts;
        const freeProducts = pricingRule.mPricingXProducts.filter((prod) => prod.isFree === "Y");
        // const conditionalProducts = pricingRule.mPricingXProducts.filter((prod)=> prod.isFree === "N");
        const prodIndexes = [];
        let freeProductIndex;
        let minimumSet = 1;

        addedToCart.map((item, index) => {
          const ifExistis = offerProducts.findIndex((op) => op.mProductId === item.productId);
          if (ifExistis >= 0) {
            if (item.productId === freeProducts[0].mProductId) {
              freeProductIndex = index;
            }
            if (parseFloat(item.weight) <= minimumSet) {
              minimumSet = parseFloat(item.weight);
            }
            prodIndexes.push(index);
          }
          return null;
        });

        if (prodIndexes.length === pricingRule.mPricingXProducts.length) {
          addedToCart[freeProductIndex].weight = minimumSet;
          const sp = 0;
          const mrp = sp * addedToCart[freeProductIndex].weight;
          const tax = mrp - mrp / (1 + addedToCart[freeProductIndex].taxRate / 100);
          addedToCart[freeProductIndex].salePrice = sp;
          addedToCart[freeProductIndex].taxAmount = tax;
          addedToCart[freeProductIndex].nettotal = mrp;
          addedToCart[freeProductIndex].discount = addedToCart[freeProductIndex].realPrice * addedToCart[freeProductIndex].weight;
          addedToCart[freeProductIndex].discountName = pricingRule.printedName;
          addedToCart[freeProductIndex].mPricingruleId = pricingRule.mPricingrulesId;
          addedToCart[freeProductIndex].nameOriginal = addedToCart[freeProductIndex].nameOriginal ? addedToCart[freeProductIndex].nameOriginal : addedToCart[freeProductIndex].name;
          addedToCart[freeProductIndex].name = addedToCart[freeProductIndex].nameOriginal + "  " + pricingRule.printedName;
          // message.success(pricingRule.printedName);
        } else {
          const mrp = parseFloat(addedToCart[freeProductIndex].realPrice) * addedToCart[freeProductIndex].weight;
          const tax = mrp - mrp / (1 + addedToCart[freeProductIndex].taxRate / 100);
          addedToCart[freeProductIndex].taxAmount = tax;
          addedToCart[freeProductIndex].nettotal = mrp;
          addedToCart[freeProductIndex].discount = 0;
          addedToCart[freeProductIndex].discountName = "";
          addedToCart[freeProductIndex].mPricingruleId = null;
          delete addedToCart[freeProductIndex].nameOriginal;
        }

        let totalTax = 0;
        let totalPrice = 0;
        let totalItemsQty = 0;
        let totalDiscounts = 0;
        for (let i = 0; i < addedToCart.length; i += 1) {
          totalPrice += addedToCart[i].nettotal;
          totalItemsQty += addedToCart[i].weight;
          totalTax += addedToCart[i].taxAmount;
          totalDiscounts += addedToCart[i].discount;
          addedToCart[i].key = i;
        }

        const roundOffValue = Math.round(totalPrice);
        const totalRoundOff = totalPrice - roundOffValue;
        totalPrice = roundOffValue;

        setCart({
          ...cart,
          items: [...addedToCart],
          total: totalPrice,
          tax: totalTax,
          discount: totalDiscounts,
          totalQty: totalItemsQty,
          roundOff: totalRoundOff,
        });
      }
    }
  };

  const [documentSequence, setDocumnetSequence] = useState(tillDocumentSequence);
  const [activeOrderProcess, setActiveOrderProcess] = useState(false);
  let orderState = 0;

  const processOrder = () => {
    orderState += 1;
    if (orderState === 1 && cart.total > 0) {
      processOrderApproved();
    } else {
      setPaymentModal(false);
    }
  };

  const processOrderApproved = () => {
    if (!activeOrderProcess) {
      setActiveOrderProcess(true);

      const cartToDb = cart;
      const newDocumentSequence = documentSequence + 1;

      const tillSession = JSON.parse(localStorage.getItem("tillSession"));
      const tillSessionId = tillSession.tillSessionId;

      const change = cartToDb.paid - cartToDb.total;
      cartToDb.change = change;
      const cPi = cartToDb.payments.findIndex((pi) => pi.name.toLowerCase() === "cash");

      if (cPi >= 0) {
        const updatedCashAmt = parseFloat(cartToDb.payments[cPi].amount) - change;
        cartToDb.payments[cPi].amount = `${updatedCashAmt}`;
      } else {
        const updatedCashAmt = parseFloat(cartToDb.payments[0].amount) - change;
        cartToDb.payments[0].amount = `${updatedCashAmt}`;
      }

      if (cartToDb.customer.loyaltyLevel.cwrLoyaltyLevelId) {
        cartToDb.accumulationPoints = parseFloat(cartToDb.customer.loyaltyLevel.accumulationRate) * cartToDb.total;
      }

      // cartToDb.sOrderID = uuidv4().replace(/-/g, "").toUpperCase();
      cartToDb.orderTime = timeStamp();
      cartToDb.createdBy = tillData.tillAccess.csUserId;
      cartToDb.documentno = `${tillData.tillAccess.cwrTill.prefix}${newDocumentSequence}`;
      cartToDb.orderType = orderType.cwrSaletype.cwrSaletypeId;
      cartToDb.tillSessionId = tillSessionId;
      cartToDb.isSynced = 0;
      cartToDb.syncAttempts = 0;
      cartToDb.customerSearchKey = cartToDb.customer.code;

      // console.log("Order ->", cartToDb);

      db.orders
        .add(cartToDb)
        .then(() => {
          setPaymentModal(false);
          deleteCart();
          localStorage.setItem("documentSequence", newDocumentSequence);
          setDocumnetSequence(newDocumentSequence);
          message.success(`Order ${cartToDb.documentno} Created Successfully`);
          orderState = 0;
          setActiveOrderProcess(false);
          setTimeout(() => {
            if (isPrintModeXML) {
              xmlPrint(cartToDb, "Receipt");
            } else {
              htmlPrint(cartToDb, "Receipt");
            }
          }, 1500);
        })
        .catch((error) => {
          console.error("Failed to place an order: ", error);
        });
    }
  };

  //// CART OPERATIONS END ////

  // OFFERS AND DISCOUNTS BLOCK START
  const [offerProductsList, setOfferProductList] = useState();
  const [displayOfferProductSelectiton, setDisplayOfferProductSelection] = useState(false);

  const selectOfferProduct = (product) => {
    const pro = product;
    /* const billCart = cart;
    billCart.totalQty = billCart.totalQty + 1;
    billCart.items.push(pro);
    setCart(billCart); */
    addProduct(pro, 1);
    setDisplayOfferProductSelection(false);
    message.success(`${pro.name} (Free) Added Successfully`);
    setPaymentModal(true);
  };
  // OFFERS AND DISCOUNTS BLOCK START

  //// PAYMENTS BLOCK START ////
  const [paymentModal, setPaymentModal] = useState(false);
  const [amount, setAmount] = useState("");

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [loader, setLoader] = useState(false);

  const onChangeAmount = (event) => {
    setAmount(event.target.value);
  };

  const handleAmount = (value) => {
    if (amount === "" && value === "x") {
      setAmount("");
    } else if (value === "x") {
      setAmount(`${amount.toString().substring(0, amount.toString().length - 1)}`);
    } else {
      setAmount(`${amount}${value}`);
    }
  };

  const openPaymentModal = () => {
    if (cart.total > 0) {
      db.pricingRules.toArray().then((pr) => {
        const pricingRuleCount = pr.length;
        if (pricingRuleCount > 0) {
          for (let i = 0; i < pricingRuleCount; i++) {
            const pricingRule = pr[i];
            if (timeValidator(pricingRule.startDate, pricingRule.endDate)) {
              if (pricingRule.timeSpecific === "Y") {
                const pStartTime = pricingRule.starttime.substring(11);
                const pEndTIme = pricingRule.endtime.substring(11);
                const starttime = timeStamp().substring(0, 10) + " " + pStartTime;
                const endtime = timeStamp().substring(0, 10) + " " + pEndTIme;
                const weekDay = currentDay();
                if (timeValidator(starttime, endtime) && pricingRule[weekDay] === "Y") {
                  processBillDiscounts(pricingRule);
                } else {
                  setPaymentModal(true);
                }
              } else {
                processBillDiscounts(pricingRule);
              }
            } else {
              setPaymentModal(true);
            }
          }
        } else {
          setPaymentModal(true);
        }
      });
    }
  };

  const processBillDiscounts = (pricingRule) => {
    if (pricingRule.type === "TD") {
      const saleTypeValidation = pricingRule.cwrSaletypeId === null ? true : pricingRule.cwrSaletypeId === orderType.cwrSaletype.cwrSaletypeId;
      const billCart = { ...cart };

      let discountValidator = true;

      if (pricingRule.mPricingCcategories.length > 0) {
        discountValidator = pricingRule.mPricingCcategories.some((pc) => pc.mPricingCcategoryId === cart.customer.sCustomer.customerCategory.sCustomerCateforyId);
      }

      if (pricingRule.mPricingPcategories.length > 0) {
        let conditionValidatorCount = 0;
        billCart.items.map((item) => {
          const stateValidation = pricingRule.mPricingPcategories.some((pc) => pc.mPricingPcategoryId === item.mProductCategoryId);
          if (stateValidation) {
            conditionValidatorCount += 1;
          }
          return null;
        });
        if (billCart.items.length !== conditionValidatorCount) {
          discountValidator = false;
        }
      }

      if (pricingRule.mPricingBrands.length > 0) {
        let conditionValidatorCount = 0;
        billCart.items.map((item) => {
          const stateValidation = pricingRule.mPricingBrands.some((pc) => pc.mPricingBrandId === item.productBrandId);
          if (stateValidation) {
            conditionValidatorCount += 1;
          }
          return null;
        });
        if (billCart.items.length !== conditionValidatorCount) {
          discountValidator = false;
        }
      }

      if (pricingRule.mPricingBUnits.length > 0) {
        let conditionValidatorCount = 0;
        billCart.items.map((item) => {
          const stateValidation = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === item.csBunitId);
          if (stateValidation) {
            conditionValidatorCount += 1;
          }
          return null;
        });
        if (billCart.items.length !== conditionValidatorCount) {
          discountValidator = false;
        }
      }

      if (saleTypeValidation && discountValidator) {
        if (billCart.total >= pricingRule.billAmount && billCart.total <= pricingRule.maxBillAmount) {
          let discountValue = 0;
          discountValue = pricingRule.amountDiscount !== null ? pricingRule.amountDiscount : (pricingRule.percentageDiscount / 100) * billCart.total;
          const roundOffValue = Math.round(billCart.total - discountValue);
          const totalRoundOff = billCart.total - roundOffValue;
          billCart.mPricingruleId = pricingRule.mPricingrulesId;
          billCart.billDiscount = billCart.billDiscount ? billCart.billDiscount + discountValue : discountValue;
          billCart.roundOff = totalRoundOff;
          billCart.total = roundOffValue;
          billCart.discount += discountValue;
          setCart({ ...billCart });
          setPaymentModal(true);
          message.success(pricingRule.printedName);
        }
      }
    } else if (pricingRule.type === "TDF") {
      const saleTypeValidation = pricingRule.cwrSaletypeId === null ? true : pricingRule.cwrSaletypeId === orderType.cwrSaletype.cwrSaletypeId;
      let discountValidator = true;
      const billCart = { ...cart };

      if (pricingRule.mPricingCcategories.length > 0) {
        discountValidator = pricingRule.mPricingCcategories.some((pc) => pc.mPricingCcategoryId === cart.customer.sCustomer.customerCategory.sCustomerCateforyId);
      }

      if (pricingRule.mPricingPcategories.length > 0) {
        let conditionValidatorCount = 0;
        billCart.items.map((item) => {
          const stateValidation = pricingRule.mPricingPcategories.some((pc) => pc.mPricingPcategoryId === item.mProductCategoryId);
          if (stateValidation) {
            conditionValidatorCount += 1;
          }
          return null;
        });
        if (billCart.items.length !== conditionValidatorCount) {
          discountValidator = false;
        }
      }

      if (pricingRule.mPricingBrands.length > 0) {
        let conditionValidatorCount = 0;
        billCart.items.map((item) => {
          const stateValidation = pricingRule.mPricingBrands.some((pc) => pc.mPricingBrandId === item.productBrandId);
          if (stateValidation) {
            conditionValidatorCount += 1;
          }
          return null;
        });
        if (billCart.items.length !== conditionValidatorCount) {
          discountValidator = false;
        }
      }

      if (pricingRule.mPricingBUnits.length > 0) {
        let conditionValidatorCount = 0;
        billCart.items.map((item) => {
          const stateValidation = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === item.csBunitId);
          if (stateValidation) {
            conditionValidatorCount += 1;
          }
          return null;
        });
        if (billCart.items.length !== conditionValidatorCount) {
          discountValidator = false;
        }
      }

      if (saleTypeValidation && discountValidator) {
        if (billCart.total >= pricingRule.billAmount && billCart.total <= pricingRule.maxBillAmount) {
          if (pricingRule.mPricingXProducts.length > 0) {
            if (pricingRule.mPricingXProducts.length === 1) {
              db.products
                .where("mProductId")
                .equalsIgnoreCase(pricingRule.mPricingXProducts[0].mProductId)
                .toArray()
                .then((product) => {
                  if (product.length > 0) {
                    const obj = product[0];
                    let batchno = null;
                    let mBatchId = null;
                    let upc = null;
                    //console.log(obj);
                    if (obj.batchedProduct === "Y") {
                      const bI = obj.mBatch.findIndex((p) => p.mBatchId === pricingRule.mPricingXProducts[0].mBatchId);
                      batchno = obj.mBatch[bI].batchno;
                      mBatchId = obj.mBatch[bI].mBatchId;
                      upc = obj.mBatch[bI].upc;
                    }
                    const productDefined = {
                      batchno: batchno,
                      description: obj.description,
                      discount: 0,
                      discountName: "",
                      imageurl: obj.imageurl,
                      isDecimal: obj.isDecimal,
                      isManualQty: obj.isManualQty,
                      isPromoApplicable: false,
                      isReturn: false,
                      mBatchId: mBatchId,
                      mPricingruleId: null,
                      name: obj.name,
                      nettotal: 0,
                      primaryOrderLine: null,
                      productId: obj.mProductId,
                      realPrice: 0,
                      returnQty: null,
                      salePrice: 0,
                      stock: obj.onhandQty,
                      tax: obj.cTaxId,
                      taxAmount: 0,
                      taxRate: obj.taxRate,
                      uom: obj.csUomId,
                      uom_name: obj.uomName,
                      upc: upc,
                      value: obj.value,
                      weight: 1,
                      shortDescription: obj.shortDescription,
                      hsncode: obj.hsncode,
                      csBunitId: obj.csBunitId,
                      mProductCategoryId: obj.mProductCategoryId,
                      productManufacturerId: obj.productManufacturerId,
                      productBrandId: obj.productBrandId,
                    };
                    addProduct(productDefined, 1);
                    message.success(pricingRule.printedName);
                    setPaymentModal(true);
                  }
                });
            } else {
              const offerProductsStack = [];
              for (let i = 0; i < pricingRule.mPricingXProducts.length; i++) {
                db.products
                  .where("mProductId")
                  .equalsIgnoreCase(pricingRule.mPricingXProducts[i].mProductId)
                  .toArray()
                  .then((product) => {
                    //console.log(product);
                    if (product.length > 0) {
                      const obj = product[0];
                      let batchno = null;
                      let mBatchId = null;
                      let upc = null;
                      if (obj.batchedProduct === "Y") {
                        const bI = obj.mBatch.findIndex((p) => p.mBatchId === pricingRule.mPricingXProducts[i].mBatchId);
                        if (bI >= 0) {
                          batchno = obj.mBatch[bI].batchno;
                          mBatchId = obj.mBatch[bI].mBatchId;
                          upc = obj.mBatch[bI].upc;
                        }
                      }

                      const productDefined = {
                        batchno: batchno,
                        description: obj.description,
                        discount: 0,
                        discountName: "",
                        imageurl: obj.imageurl,
                        isDecimal: obj.isDecimal,
                        isManualQty: obj.isManualQty,
                        isReturn: false,
                        isPromoApplicable: false,
                        mBatchId: mBatchId,
                        mPricingruleId: null,
                        name: obj.name,
                        nettotal: 0,
                        primaryOrderLine: null,
                        productId: obj.mProductId,
                        realPrice: 0,
                        returnQty: null,
                        salePrice: 0,
                        stock: obj.onhandQty,
                        tax: obj.cTaxId,
                        taxAmount: 0,
                        taxRate: obj.taxRate,
                        uom: obj.csUomId,
                        uom_name: obj.uomName,
                        upc: upc,
                        value: obj.value,
                        weight: 1,
                        shortDescription: obj.shortDescription,
                        hsncode: obj.hsncode,
                        csBunitId: obj.csBunitId,
                        mProductCategoryId: obj.mProductCategoryId,
                        productManufacturerId: obj.productManufacturerId,
                        productBrandId: obj.productBrandId,
                      };
                      offerProductsStack.push(productDefined);
                    }
                  });
              }
              setTimeout(() => {
                setOfferProductList([...offerProductsStack]);
                setCart({
                  ...cart,
                  mPricingrulesId: pricingRule.mPricingrulesId,
                });
                setDisplayOfferProductSelection(true);
              }, 1000);
            }
          }
        }
      }
    } else {
      setPaymentModal(true);
    }
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
    setSelectedPaymentMethod("");
    const billCart = { ...cart };
    const pI = billCart.items.findIndex((p) => parseFloat(p.salePrice) === 0);
    if (pI >= 0) billCart.items.splice(pI, 1);
    const billCartItems = billCart.items;
    billCart.mPricingruleId = null;
    const bd = billCart.billDiscount ? billCart.billDiscount : 0;
    billCart.discount -= bd;
    billCart.total += bd;
    delete billCart.billDiscount;
    setCart({ ...billCart, items: [...billCartItems] });
  };

  const [loyaltyPaymentOtp, setLoyaltyPaymentOtp] = useState();
  const [loyalityOtpModalVisible, setLoyalityOtpModalVisible] = useState(false);
  const [loyalityOtpData, setLoyalityOtpData] = useState();

  const processOtpInput = () => {
    const { otp, paymentMethod, value, redeemPoints } = loyalityOtpData;
    setLoyalityOtpModalVisible(false);
    if (otp === loyaltyPaymentOtp) {
      message.success("Loyality Points Reedemed !");
      paymentMethod.redemptionPoints = parseInt(redeemPoints);
      processPayment(paymentMethod, value);
    } else {
      message.warning("Invalid OTP");
    }
    setLoyalityOtpData();
    setLoyaltyPaymentOtp();
  };

  const processInnovitiEDCPayment = (amountAdded, currentPaymentMethod, paymentMethod, value) => {
    if (amountAdded >= 1) {
      const requestCode = innovitiPaymentCodes[currentPaymentMethod.name];
      const trxId = uuidv4().replace(/-/g, "").toUpperCase();
      const cashierId = tillData.tillAccess.csUserId;
      const customerMobileNo = cart.customer.mobileNo;
      setLoader(true);
      setPaymentModal(false);
      console.info(`Innoviti Request URL: ${edcUrl}?value=0,${requestCode},${trxId}!${cashierId}!${customerMobileNo}!,${amountAdded.toString().split(".").join("")}`);
      Axios.get(`${edcUrl}?value=0,${requestCode},${trxId}!${cashierId}!${customerMobileNo}!,${amountAdded.toString().split(".").join("")}`)
        .then((response) => {
          const result = response.data;
          const { ResponseCode, ResponseMessage } = result;
          if (ResponseCode === "00") {
            setLoader(false);
            setPaymentModal(true);
            message.success("Payment Success");
            processPayment(paymentMethod, value);
          } else {
            setLoader(false);
            setPaymentModal(true);
            message.error(`Payment Failed: ${ResponseMessage}`);
          }
        })
        .catch((error) => {
          console.error("Payment Failed:", error);
          setLoader(false);
          setPaymentModal(true);
          message.error("Payment Failed: Transaction timeout / Check EDC Connection");
        });
    } else {
      console.warn("Minimum amount not satisfied");
    }
  };

  const cancelPaytmVerifyPaymentRequest = (options) => {
    options.data.type = 6;
    Axios(options);
  };

  const paytmVerifyEDCPaymentStatus = (options) => {
    return new Promise(async function (verify) {
      Modal.confirm({
        title: "Payment request initiated",
        content: "Please verify",
        okText: "Verify",
        onOk: () => {
          verify("retry");
        },
        onCancel: () => {
          cancelPaytmVerifyPaymentRequest(options);
          verify("cancel");
        }
      });
    });
  };

  const chekPaytmEDCPaymentStatus = (options) => {
    return new Promise(async function (process, reject) {
      let retries = true;
      while (retries) {
        try {
          const response = await Axios(options);
          const result = response.data;
          if (result.resultCode === "200") {
            retries = false;
            process(true);
          } else if (result.resultCode === "103") {
            const retryStatus = await paytmVerifyEDCPaymentStatus(options);
            if (retryStatus === "retry") {
              retries = true;
            } else {
              retries = false;
              process(false);
            }
          } else {
            retries = false;
            process(false);
          }
        } catch (err) {
          retries = false;
          reject(err);
        }
      }
    });
  };

  const processPaytmEDCPayment = (amountAdded, currentPaymentMethod, paymentMethod, value) => {
    const trxId = uuidv4().replace(/-/g, "").toUpperCase();
    setLoader(true);
    setPaymentModal(false);
    const requestData = {
      url: "http://localhost:3636/payment",
      method: "POST",
      data: {
        type: 0,
        transactionID: trxId,
        terminalID: "111",
        currency: "INR",
        amount: amountAdded,
        properties: {},
        paymentProvider: "Paytm",
      },
      headers: {
        "Content-Type": "Application/json",
      },
    };

    chekPaytmEDCPaymentStatus(requestData)
      .then((response) => {
        if (response) {
          setLoader(false);
          setPaymentModal(true);
          message.success("Payment Success");
          processPayment(paymentMethod, value);
        } else {
          setLoader(false);
          setPaymentModal(true);
          message.error("Payment Failed");
        }
      })
      .catch((error) => {
        console.error("Payment Failed:", error);
        setLoader(false);
        setPaymentModal(true);
        message.error("Payment Failed: Transaction timeout / Check EDC Connection");
      });
  };

  const [paymentModalLoyalityMessages, setPaymentModalLoyalityMessages] = useState({ inputMessage: "Enter Amount", buttonText: "Add Payment" });
  useEffect(() => {
    if (selectedPaymentMethod.isloyalty) {
      setPaymentModalLoyalityMessages({ inputMessage: "Enter Points to Reedem", buttonText: "Reedem" });
    } else {
      setPaymentModalLoyalityMessages({ inputMessage: "Enter Amount", buttonText: "Add Payment" });
    }
  }, [selectedPaymentMethod]);

  const requestPayment = (paymentMethod, value, grant) => {
    if (grant !== undefined) {
      processPayment(paymentMethod, value);
    } else {
      const validation = RegExp("^[0-9]+(.[0-9]{1,2})?$");
      if (paymentMethod !== undefined && paymentMethod !== "" && validation.test(parseFloat(value))) {
        const currentPaymentMethod = paymentMethod;
        const amountAdded = parseFloat(value).toFixed(2);
        if (currentPaymentMethod.integratedPayment) {
          if (currentPaymentMethod.paymentProvider === "INV") {
            processInnovitiEDCPayment(amountAdded, currentPaymentMethod, paymentMethod, value);
          } else if (currentPaymentMethod.paymentProvider === "PTM") {
            processPaytmEDCPayment(amountAdded, currentPaymentMethod, paymentMethod, value);
          } else {
            message.error("Invalid payment provider configuration");
          }
        } else if (currentPaymentMethod.iscredit) {
          if (cart.customer.iscredit) {
            setLoader(true);
            setPaymentModal(false);
            Axios({
              url: serverUrl,
              method: "POST",
              data: {
                query: `query{
                verifyCredit(customerId:"${cart.customer.cwrCustomerId}"){
                    iscredit
                    creditLimit
                }
            }`,
              },
              headers: {
                "Content-Type": "Application/json",
                Authorization: `${tokens.token_type} ${tokens.access_token}`,
              },
            })
              .then((creditResponse) => {
                const { iscredit, creditLimit } = creditResponse.data.data.verifyCredit;
                if (iscredit) {
                  if (amountAdded > 0 && amountAdded <= creditLimit) {
                    message.success("Credit Payment Granted");
                    processPayment(paymentMethod, value);
                  } else {
                    message.warning("Credit limit exceeded!");
                  }
                } else {
                  message.warning("Customer not eligible for credit payment !");
                }
              })
              .catch((err) => {
                console.error(err);
                message.error("Payment Failed !");
              })
              .finally(() => {
                setLoader(false);
                setPaymentModal(true);
              });
          } else {
            message.warning("Credit payment not available for customer !");
          }
        } else if (currentPaymentMethod.isloyalty) {
          setLoader(true);
          setPaymentModal(false);
          if (cart.customer.loyaltyLevel.cwrLoyaltyLevelId) {
            const redeemptionRate = cart.customer.loyaltyLevel.redemptionRate;
            const redeemPoints = parseFloat(value);
            const amountForRedeem = parseFloat(redeemptionRate) * redeemPoints;
            if (redeemPoints <= parseFloat(cart.customer.retlLoyaltyBalance)) {
              Axios({
                url: serverUrl,
                method: "POST",
                data: {
                  query: `mutation{
                    verifyLoyalty(customerId:"${cart.customer.cwrCustomerId}", redeemPoint:${redeemPoints}){
                      status
                      message
                      otp
                    }
                  }`,
                },
                headers: {
                  "Content-Type": "Application/json",
                  Authorization: `${tokens.token_type} ${tokens.access_token}`,
                },
              })
                .then((loyalityResponse) => {
                  // const loyalityResponse = {data:{data:{verifyLoyalty:{ status: "Success", otp: "12345", message: "OTP Generated !"}}}};
                  const { status, otp } = loyalityResponse.data.data.verifyLoyalty;
                  const responseMessage = loyalityResponse.data.data.verifyLoyalty.message;
                  if (status === "Success") {
                    message.success(responseMessage);
                    setLoyalityOtpData({ otp: otp, paymentMethod: paymentMethod, value: amountForRedeem, redeemPoints: redeemPoints });
                    setLoyalityOtpModalVisible(true);
                  } else {
                    message.warning(responseMessage);
                  }
                })
                .catch((err) => {
                  console.error(err);
                  message.error("Payment Failed !");
                })
                .finally(() => {
                  setLoader(false);
                  setPaymentModal(true);
                });
            } else {
              message.warning("Insuffient loyality points !");
              setLoader(false);
              setPaymentModal(true);
            }
          } else {
            message.warning("Loyalty not found for selected customer !");
            setLoader(false);
            setPaymentModal(true);
          }
        } else {
          processPayment(paymentMethod, value);
        }
      } else {
        message.warn("Invalid Payment !");
      }
    }
  };

  const processPayment = (paymentMethod, value) => {
    const paymentAddons = cart.payments;
    const amountAdded = parseFloat(value);
    const currentPaymentMethod = paymentMethod;
    let shouldRemoveRedeemPoints = false;

    if (paymentMethod.isloyalty && amountAdded < 0) {
      shouldRemoveRedeemPoints = true;
    }

    const index = paymentAddons.findIndex((p) => p.finPaymentmethodId === currentPaymentMethod.finPaymentmethodId);
    if (index >= 0) {
      const newAmount = parseFloat(paymentAddons[index].amount) + parseFloat(amountAdded);
      paymentAddons[index].amount = parseFloat(newAmount).toFixed(2);
      if (parseFloat(paymentAddons[index].amount) === 0) {
        paymentAddons.splice(index, 1);
      }
    } else {
      if (amountAdded !== 0) {
        currentPaymentMethod.amount = parseFloat(amountAdded).toFixed(2);
        paymentAddons.push(currentPaymentMethod);
      }
    }

    let totalAmountAdded = 0;
    const paymentMethodsCount = paymentAddons.length;
    for (let i = 0; i < paymentMethodsCount; i += 1) {
      totalAmountAdded += parseFloat(paymentAddons[i].amount);
    }

    if (paymentMethod.redemptionPoints) {
      cart.redemptionPoints = paymentMethod.redemptionPoints;
    }

    const redemptionPts = shouldRemoveRedeemPoints ? 0 : cart.redemptionPoints;

    let creditAmountValue = cart.creditAmount;
    if (paymentMethod.iscredit && amountAdded > 0) {
      creditAmountValue = amountAdded;
    }

    if (paymentMethod.iscredit && amountAdded < 0) {
      creditAmountValue = 0;
    }

    setCart({
      ...cart,
      paid: totalAmountAdded,
      payments: [...paymentAddons],
      redemptionPoints: redemptionPts,
      creditAmount: creditAmountValue,
    });
    setSelectedPaymentMethod("");
    setAmount("");
  };
  //// PAYMENTS BLOCK END ////

  // ORDER SYNC BLOCK START
  const syncOrders = (syncTrigger) => {
    const nWStatus = navigator.onLine ? "online" : "offline";
    if (nWStatus === "online") {
      const csCurrencyId = localStorage.getItem("csCurrencyId");
      db.orders
        .where("isSynced")
        .equals(0)
        .toArray()
        .then((orders) => {
          const pendingOrdersCount = orders.length;
          if (pendingOrdersCount > 0) {
            for (let i = 0; i < pendingOrdersCount; i += 1) {
              const orderLines = [];
              for (let j = 0; j < orders[i].items.length; j += 1) {
                let unitPrice = orders[i].items[j].nettotal / orders[i].items[j].weight;
                unitPrice = parseFloat(unitPrice).toFixed(2);
                orderLines.push(`{
              sOrderlineID: "${uuidv4().replace(/-/g, "").toUpperCase()}",
              created: "${orders[i].orderTime}",
              createdby: "${orders[i].createdBy}",
              updated: "${orders[i].orderTime}",
              updatedby: "${orders[i].createdBy}",
              sOrderId: "${orders[i].sOrderID}",
              line: ${(j + 1) * 10},
              description: "",
              mProductId: "${orders[i].items[j].productId}",
              csUomId: "${orders[i].items[j].uom}",
              csTaxId: "${orders[i].items[j].tax}",
              qty: ${orders[i].items[j].weight},
              unitprice: ${unitPrice},
              netlist: ${orders[i].items[j].salePrice}
              discount: ${orders[i].items[j].discount},
              returnline: false,
              returnQty: 0,
              mBatchId: ${orders[i].items[j].mBatchId !== null ? `"${orders[i].items[j].mBatchId}"` : null},
					    mPricingruleId: ${orders[i].items[j].mPricingruleId !== null && orders[i].items[j].mPricingruleId !== undefined ? `"${orders[i].items[j].mPricingruleId}"` : null}
            }`);
              }
              const paymentsList = [];
              let amtMax = 0;
              let maxFinPaymentMethod;
              for (let k = 0; k < orders[i].payments.length; k += 1) {
                if (amtMax < parseFloat(orders[i].payments[k].amount)) {
                  amtMax = parseFloat(orders[i].payments[k].amount);
                  maxFinPaymentMethod = orders[i].payments[k].finPaymentmethodId;
                }
                paymentsList.push(`{
                  finPaymentmethodID: "${orders[i].payments[k].finPaymentmethodId}",
                  amount: "${orders[i].payments[k].amount}"
                }`);
              }
              const paramsInput = {
                query: `mutation{
              posOrderProcessor(posOrder:{
              sOrderID: "${orders[i].sOrderID}",
              cSClientID: "${tillData.tillAccess.csClientId}",
              cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}",
              created: "${orders[i].orderTime}",
              createdby: "${orders[i].createdBy}",
              updated: "${orders[i].orderTime}",
              updatedby: "${orders[i].createdBy}",
              csDoctypeId: "${tillData.tillAccess.csBunit.cwrCsDoctypeId}",
              sCustomerId: "${tillData.tillAccess.csBunit.cwrSCustomerId}",
              sCustomerBillingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
              sCustomerShippingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
              sPricelistId: "${tillData.tillAccess.csBunit.cwrSpricelistId}",
              documentno: "${orders[i].documentno}",
              dateordered: "${orders[i].orderTime}",
              datepromised: "${orders[i].orderTime}",
              csPaymenttermID: null,
              finPaymentmethodId: "${maxFinPaymentMethod}",
              csCurrencyId: "${csCurrencyId}",
              mWarehouseId: "${tillData.tillAccess.csBunit.mWarehouse.mWarehouseID}",
              cwrLongitude: "",
              cwrLatitude: "",
              csUserId: "${tillData.tillAccess.csUserId}",
              cwrB2cCustomerId: "${orders[i].customer.cwrCustomerId}",
              orderreference: "",
              cwrPayref: "",
              cwrPayremarks: "",
              description: "",
              cwrTillId: "${tillData.tillAccess.cwrTill.cwrTillID}",
              redemption: ${orders[i].redemptionPoints},
              accumulation: ${orders[i].accumulationPoints},
              roundoff: 0,
              cwrProductQty: ${orders[i].items.length},
              cwrProductCount: ${orders[i].totalQty},
              ofdStatus: "Delivered",
              ofdIspaid: "Y",
              mPricingruleId: ${orders[i].mPricingruleId !== null && orders[i].mPricingruleId !== undefined ? `"${orders[i].mPricingruleId}"` : null},
			        cwrSaletypeId: "${orders[i].orderType}",
              discAmount: ${orders[i].discount},
              creditAmount: ${orders[i].creditAmount},
              pricingCoupon:{
                mPricingCouponId:${orders[i].mPricingCouponId ? `"${orders[i].mPricingCouponId}"` : null},
                redemptionCount:${orders[i].couponRedemptionCount ? parseInt(orders[i].couponRedemptionCount) : null},
              }
              payments: [${paymentsList}],
              line: [${orderLines}]
              })
            {
              documentno 
              status
              message
             }
            }
            `,
              };
              Axios({
                url: serverUrl,
                method: "POST",
                data: paramsInput,
                headers: {
                  "Content-Type": "Application/json",
                  Authorization: `${tokens.token_type} ${tokens.access_token}`,
                },
              })
                .then((response) => {
                  const result = response.data.data.posOrderProcessor;
                  const { status } = result;
                  if (status === "200") {
                    console.info(`Order ${orders[i].documentno} synced to Server`);
                    db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ isSynced: 1 });
                  } else {
                    console.error("Failed Order Sync ====> ", response);
                    const syncFailedCount = parseInt(orders[i].syncAttempts) + 1;
                    if (parseInt(orders[i].syncAttempts) < 100) {
                      db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ syncAttempts: syncFailedCount });
                    } else {
                      db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ isSynced: 2 });
                    }
                  }
                })
                .catch((error) => {
                  const err = JSON.parse(JSON.stringify(error));
                  const { message } = err;
                  if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
                    const refToken = tokens.refresh_token;
                    const requestBody = {
                      grant_type: "refresh_token",
                      refresh_token: refToken,
                    };
                    const config = {
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: "Basic dGFsazJhbWFyZXN3YXJhbjpteS1zZWNyZXQ=",
                      },
                    };
                    Axios.post(serverTokenUrl, qs.stringify(requestBody), config).then((newToken) => {
                      setAuthTokens(newToken.data);
                    });
                  } else {
                    const syncFailedCount = parseInt(orders[i].syncAttempts) + 1;
                    if (parseInt(orders[i].syncAttempts) < 100) {
                      db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ syncAttempts: syncFailedCount });
                    } else {
                      db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ isSynced: 2 });
                    }
                  }
                });
            }
          }
        });
    }
    if (syncTrigger === "orderHistory") {
      showOrderHistory();
    }
  };

  useEffect(() => {
    const syncOrdersInterval = setInterval(() => syncOrders(), 10000);
    return () => {
      clearTimeout(syncOrdersInterval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // ORDER SYNC BLOCK END

  /* const loginExpiredNotification = () => {
    notification.warning({
      duration: 0,
      message: "Login Expired",
      description: (
        <div>
          Your current session is expired
          <br />
          Please re-login, in order to sync orders
        </div>
      ),
    });
  }; */

  const confirmDiscardCart = () => {
    if (cart.items.length > 0) {
      Modal.confirm({
        title: "Save Cart Items ?",
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            Items in cart will be lost.
            <br />
            Please save in order to retrive later
          </div>
        ),
        okText: "Save",
        cancelText: "No",
        onOk() {
          parkBill();
          setTimeout(() => {
            history.push("/dashboard");
          }, 700);
        },
        onCancel() {
          history.push("/dashboard");
        },
      });
    } else {
      history.push("/dashboard");
    }
  };

  useEffect(() => {
    if (parkedList.length > 0) {
      selectParkedBill(parkedList[0]);
    }
  }, []); // eslint-disable-line

  const [couponModalVisible, setCouponModalVisible] = useState(false);
  const [couponInput, setCouponInput] = useState("");

  const closeCouponModal = () => {
    setCouponModalVisible(false);
    setCouponInput("");
  };

  const checkCoupon = async () => {
    setCouponModalVisible(false);
    const verifyCouponResponse = await Axios({
      url: serverUrl,
      method: "POST",
      data: {
        query: `query {
          verifyCoupons(couponcode: "${couponInput}") {
            mPricingCouponId
            csClientId
            csBunitId
            created
            createdBy
            updated
            updatedBy
            line
            mPricingRulesId
            couponcode
            status
            redemptionCount
            usedDate
            upc
          }
        }`,
      },
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    });
    const { verifyCoupons } = verifyCouponResponse.data.data;
    if (verifyCoupons.length > 0) {
      if (verifyCoupons[0].status === "A") {
        const matchingPricingRules = await db.pricingRules.where("mPricingrulesId").equalsIgnoreCase(verifyCoupons[0].mPricingRulesId).toArray();

        if (matchingPricingRules.length > 0) {
          const pricingRule = matchingPricingRules[0];
          if (pricingRule.type === "TD" && pricingRule.iscoupon === "Y") {
            const billCart = { ...cart };
            let discountValue = 0;
            discountValue = pricingRule.amountDiscount !== null ? pricingRule.amountDiscount : (pricingRule.percentageDiscount / 100) * billCart.total;
            const roundOffValue = Math.round(billCart.total - discountValue);
            const totalRoundOff = billCart.total - roundOffValue;
            billCart.mPricingruleId = pricingRule.mPricingrulesId;
            billCart.billDiscount = billCart.billDiscount ? billCart.billDiscount + discountValue : discountValue;
            billCart.roundOff = totalRoundOff;
            billCart.total = roundOffValue;
            billCart.discount += discountValue;
            billCart.mPricingCouponId = verifyCoupons[0].mPricingCouponId;
            billCart.couponRedemptionCount = parseFloat(verifyCoupons[0].redemptionCount) + 1;
            setCart({ ...billCart });
            setPaymentModal(true);
            message.success(`Coupon Applied: ${pricingRule.printedName}`);
          }

          if (pricingRule.type === "TDF" && pricingRule.iscoupon === "Y") {
            if (pricingRule.mPricingXProducts.length > 0) {
              if (pricingRule.mPricingXProducts.length === 1) {
                db.products
                  .where("mProductId")
                  .equalsIgnoreCase(pricingRule.mPricingXProducts[0].mProductId)
                  .toArray()
                  .then((product) => {
                    if (product.length > 0) {
                      const obj = product[0];
                      let batchno = null;
                      let mBatchId = null;
                      let upc = null;
                      if (obj.batchedProduct === "Y") {
                        const bI = obj.mBatch.findIndex((p) => p.mBatchId === pricingRule.mPricingXProducts[0].mBatchId);
                        batchno = obj.mBatch[bI].batchno;
                        mBatchId = obj.mBatch[bI].mBatchId;
                        upc = obj.mBatch[bI].upc;
                      }
                      const productDefined = {
                        batchno: batchno,
                        description: obj.description,
                        discount: 0,
                        discountName: "",
                        imageurl: obj.imageurl,
                        isDecimal: obj.isDecimal,
                        isManualQty: obj.isManualQty,
                        isPromoApplicable: false,
                        isReturn: false,
                        mBatchId: mBatchId,
                        mPricingruleId: null,
                        name: obj.name,
                        nettotal: 0,
                        primaryOrderLine: null,
                        productId: obj.mProductId,
                        realPrice: 0,
                        returnQty: null,
                        salePrice: 0,
                        stock: obj.onhandQty,
                        tax: obj.cTaxId,
                        taxAmount: 0,
                        taxRate: obj.taxRate,
                        uom: obj.csUomId,
                        uom_name: obj.uomName,
                        upc: upc,
                        value: obj.value,
                        weight: 1,
                        shortDescription: obj.shortDescription,
                        hsncode: obj.hsncode,
                        csBunitId: obj.csBunitId,
                        mProductCategoryId: obj.mProductCategoryId,
                        productManufacturerId: obj.productManufacturerId,
                        productBrandId: obj.productBrandId,
                      };
                      addProduct(productDefined, 1);
                      message.success(pricingRule.printedName);
                      setPaymentModal(true);
                    }
                  });
              } else {
                const offerProductsStack = [];
                for (let i = 0; i < pricingRule.mPricingXProducts.length; i++) {
                  db.products
                    .where("mProductId")
                    .equalsIgnoreCase(pricingRule.mPricingXProducts[i].mProductId)
                    .toArray()
                    .then((product) => {
                      if (product.length > 0) {
                        const obj = product[0];
                        let batchno = null;
                        let mBatchId = null;
                        let upc = null;
                        if (obj.batchedProduct === "Y") {
                          const bI = obj.mBatch.findIndex((p) => p.mBatchId === pricingRule.mPricingXProducts[i].mBatchId);
                          if (bI >= 0) {
                            batchno = obj.mBatch[bI].batchno;
                            mBatchId = obj.mBatch[bI].mBatchId;
                            upc = obj.mBatch[bI].upc;
                          }
                        }

                        const productDefined = {
                          batchno: batchno,
                          description: obj.description,
                          discount: 0,
                          discountName: "",
                          imageurl: obj.imageurl,
                          isDecimal: obj.isDecimal,
                          isManualQty: obj.isManualQty,
                          isReturn: false,
                          isPromoApplicable: false,
                          mBatchId: mBatchId,
                          mPricingruleId: null,
                          name: obj.name,
                          nettotal: 0,
                          primaryOrderLine: null,
                          productId: obj.mProductId,
                          realPrice: 0,
                          returnQty: null,
                          salePrice: 0,
                          stock: obj.onhandQty,
                          tax: obj.cTaxId,
                          taxAmount: 0,
                          taxRate: obj.taxRate,
                          uom: obj.csUomId,
                          uom_name: obj.uomName,
                          upc: upc,
                          value: obj.value,
                          weight: 1,
                          shortDescription: obj.shortDescription,
                          hsncode: obj.hsncode,
                          csBunitId: obj.csBunitId,
                          mProductCategoryId: obj.mProductCategoryId,
                          productManufacturerId: obj.productManufacturerId,
                          productBrandId: obj.productBrandId,
                        };
                        offerProductsStack.push(productDefined);
                      }
                    });
                }
                setTimeout(() => {
                  setOfferProductList([...offerProductsStack]);
                  setCart({
                    ...cart,
                    mPricingCouponId: verifyCoupons[0].mPricingCouponId,
                    couponRedemptionCount: parseFloat(verifyCoupons[0].redemptionCount) + 1,
                    mPricingrulesId: pricingRule.mPricingrulesId,
                  });
                  setDisplayOfferProductSelection(true);
                }, 1000);
              }
            }
          }

          if (pricingRule.type === "FD" && pricingRule.iscoupon === "Y") {
            const addedToCart = cart.items;
            const cartItemProductsIndexes = [];
            pricingRule.mPricingXProducts.map((prod) => {
              const cartProductIndex = addedToCart.findIndex((p) => p.productId === prod.mProductId);
              if (cartProductIndex >= 0) {
                cartItemProductsIndexes.push(cartProductIndex);
              }
              return null;
            });

            if (cartItemProductsIndexes.length > 0) {
              cartItemProductsIndexes.map((index) => {
                const discountAmt = pricingRule.amountDiscount;
                const sp = parseFloat(addedToCart[index].realPrice) - discountAmt;
                const mrp = parseFloat(sp) * addedToCart[index].weight;
                const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
                addedToCart[index].salePrice = sp;
                addedToCart[index].taxAmount = tax;
                addedToCart[index].nettotal = mrp;
                addedToCart[index].discount = discountAmt * addedToCart[index].weight;
                addedToCart[index].discountName = pricingRule.printedName;
                addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;
                addedToCart[index].nameOriginal = addedToCart[index].nameOriginal ? addedToCart[index].nameOriginal : addedToCart[index].name;
                addedToCart[index].name = addedToCart[index].nameOriginal + "  " + pricingRule.printedName;
                message.success(pricingRule.printedName);
                return null;
              });

              let totalTax = 0;
              let totalPrice = 0;
              let totalItemsQty = 0;
              let totalDiscounts = 0;
              for (let i = 0; i < addedToCart.length; i += 1) {
                totalPrice += addedToCart[i].nettotal;
                totalItemsQty += addedToCart[i].weight;
                totalTax += addedToCart[i].taxAmount;
                totalDiscounts += addedToCart[i].discount;
                addedToCart[i].key = i;
              }

              const roundOffValue = Math.round(totalPrice);
              const totalRoundOff = totalPrice - roundOffValue;
              totalPrice = roundOffValue;

              setCart({
                ...cart,
                items: [...addedToCart],
                total: totalPrice,
                tax: totalTax,
                discount: totalDiscounts,
                totalQty: totalItemsQty,
                roundOff: totalRoundOff,
                mPricingCouponId: verifyCoupons[0].mPricingCouponId,
                couponRedemptionCount: parseFloat(verifyCoupons[0].redemptionCount) + 1,
              });
            }
          }

          if (pricingRule.type === "PD" && pricingRule.iscoupon === "Y") {
            const addedToCart = cart.items;
            const cartItemProductsIndexes = [];
            pricingRule.mPricingXProducts.map((prod) => {
              const cartProductIndex = addedToCart.findIndex((p) => p.productId === prod.mProductId);
              if (cartProductIndex >= 0) {
                cartItemProductsIndexes.push(cartProductIndex);
              }
              return null;
            });
            if (cartItemProductsIndexes.length > 0) {
              cartItemProductsIndexes.map((index) => {
                const discountAmt = (pricingRule.percentageDiscount / 100) * parseFloat(addedToCart[index].realPrice);
                const sp = parseFloat(addedToCart[index].realPrice) - discountAmt;
                const mrp = parseFloat(sp) * addedToCart[index].weight;
                const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
                addedToCart[index].salePrice = sp;
                addedToCart[index].taxAmount = tax;
                addedToCart[index].nettotal = mrp;
                addedToCart[index].discount = discountAmt * addedToCart[index].weight;
                addedToCart[index].discountName = pricingRule.printedName;
                addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;
                addedToCart[index].nameOriginal = addedToCart[index].nameOriginal ? addedToCart[index].nameOriginal : addedToCart[index].name;
                addedToCart[index].name = addedToCart[index].nameOriginal + "  " + pricingRule.printedName;
                message.success(pricingRule.printedName);
                return null;
              });

              let totalTax = 0;
              let totalPrice = 0;
              let totalItemsQty = 0;
              let totalDiscounts = 0;
              for (let i = 0; i < addedToCart.length; i += 1) {
                totalPrice += addedToCart[i].nettotal;
                totalItemsQty += addedToCart[i].weight;
                totalTax += addedToCart[i].taxAmount;
                totalDiscounts += addedToCart[i].discount;
                addedToCart[i].key = i;
              }

              const roundOffValue = Math.round(totalPrice);
              const totalRoundOff = totalPrice - roundOffValue;
              totalPrice = roundOffValue;

              setCart({
                ...cart,
                items: [...addedToCart],
                total: totalPrice,
                tax: totalTax,
                discount: totalDiscounts,
                totalQty: totalItemsQty,
                roundOff: totalRoundOff,
                mPricingCouponId: verifyCoupons[0].mPricingCouponId,
                couponRedemptionCount: parseFloat(verifyCoupons[0].redemptionCount) + 1,
              });
            }
          }
        } else {
          message.warning("No offer found for coupon");
        }
      } else {
        message.warning("Cupon Expired");
      }
    } else {
      message.warning("Invalid Coupon");
    }
  };

  return (
    <div>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={loader} tip="Processing Payment">
        <BarcodeReader minLength={4} onError={onBarcodeInputError} onScan={onBarcodeInput} />

        <div style={{ background: "#0C4DA9", height: "100vh" }}>
          <Row>
            <Col span={2}>
              <img src={MenuIcon} className="menu-icon" alt="" />
            </Col>
            <Col span={10}>
              <label className="pos-head-label">{tillData.tillAccess.csBunit.name}</label>
            </Col>
            <Col span={4}>
              <label className="time-fmt">{tillData.tillAccess.cwrTill.till}</label>
            </Col>
            <Col span={4} className="right">
              <label className="time">{displayClock}</label>
            </Col>
            <Col span={4} className="right">
              <img onClick={confirmDiscardCart} src={POSLogo} className="pos-logo-icon" alt="" />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Card
                bodyStyle={{ padding: 10, height: "93.5vh" }}
                style={{
                  background: "white",
                  margin: "1%",
                  marginRight: "0.5%",
                  marginBottom: "0px",
                  height: "auto",
                  minHeight: "93.5vh",
                }}
              >
                <Row>
                  <Col span={21}>
                    <div>
                      <Row style={{ marginBottom: "2.5vh" }} gutter={[10, 20]}>
                        <Col span={12}>
                          <Card className="cardShadow card-radius" bodyStyle={{ padding: 10, height: "9vh" }} onClick={() => setDisplayOrderType(true)}>
                            <Row>
                              <Col span={21}>
                                <label className="labelA">Order Type</label>
                                <br />
                                <label className="labelB">{orderType ? orderType.cwrSaletype.name : ""}</label>
                              </Col>
                              <Col span={3}>
                                <img className="downIcon" src={DropdownCircle} alt="" />
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                        <Col span={12}>
                          <Card onClick={() => setDisplayCustomerSearch(true)} className="cardShadow card-radius" bodyStyle={{ padding: 10, height: "9vh" }}>
                            <Row>
                              <Col span={21}>
                                <label className="labelA">Customer</label>
                                <br />
                                <label className="labelB">{cart.customer.name}</label>
                              </Col>
                              <Col span={3}>
                                <img className="downIcon" src={DropdownCircle} alt="" />
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Card className="cardShadow card-radius" bodyStyle={{ padding: 0, height: "78vh" }}>
                            <Table
                              className="table-stl"
                              pagination={false}
                              dataSource={cart.items}
                              columns={[
                                {
                                  title: "Description",
                                  dataIndex: "name",
                                  key: "name",
                                },
                                {
                                  title: "Qty",
                                  dataIndex: "weight",
                                  key: "weight",
                                  render: (text) => {
                                    const wt = parseFloat(text);
                                    if (Number.isInteger(wt)) {
                                      return wt;
                                    } else {
                                      return parseFloat(text).toFixed(2);
                                    }
                                  },
                                },
                                {
                                  title: "Unit Price",
                                  dataIndex: "salePrice",
                                  key: "salePrice",
                                  render: (text) => {
                                    return parseFloat(text).toFixed(2);
                                  },
                                },
                                {
                                  title: "Net Total",
                                  dataIndex: "nettotal",
                                  key: "nettotal",
                                  render: (text) => {
                                    return parseFloat(text).toFixed(2);
                                  },
                                },
                              ]}
                              rowSelection={{ selectedRowKeys }}
                              onRow={(record) => ({
                                onClick: () => {
                                  selectProductInCart(record);
                                },
                              })}
                            />
                            <Divider style={{ height: "1.5px", margin: 0.5 }} />
                            <Row style={{ padding: 7, backgroundColor: "" }}>
                              <Col span={6}>
                                <label className="tax-dis-lbl">Tax</label>
                              </Col>
                              <Col span={18} className="amt-text-align">
                                <label className="tax-dis-lbl">
                                  <img src={Rupees} className="paid-icon" alt="" />
                                  &nbsp;
                                  {parseFloat(cart.tax).toFixed(2)}
                                </label>
                              </Col>
                            </Row>
                            <Row style={{ padding: 7, backgroundColor: "" }}>
                              <Col span={6}>
                                <label className="tax-dis-lbl">Discounts</label>
                              </Col>
                              <Col span={18} className="amt-text-align">
                                <label className="tax-dis-lbl">
                                  <img src={Rupees} className="paid-icon" alt="" />
                                  &nbsp; {parseFloat(cart.discount).toFixed(2)}
                                </label>
                              </Col>
                            </Row>
                            <Row style={{ padding: 7, backgroundColor: "" }}>
                              <Col span={6}>
                                <label className="tax-dis-lbl">Items / Qty</label>
                              </Col>
                              <Col span={18} className="amt-text-align">
                                <label className="tax-dis-lbl">
                                  {cart.items.length} / {parseFloat(cart.totalQty).toFixed(2)}
                                </label>
                              </Col>
                            </Row>
                            <Row style={{ padding: 7 }}>
                              <Button
                                size="large"
                                style={{
                                  width: "100%",
                                  height: "6vh",
                                  borderRadius: "7px",
                                  background: "#20B883",
                                }}
                                onClick={openPaymentModal}
                              >
                                <Row>
                                  <Col span={12} style={{ textAlign: "left" }}>
                                    <label
                                      style={{
                                        color: "#ffffff",
                                        fontSize: "22px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Total
                                    </label>
                                  </Col>
                                  <Col span={12} style={{ textAlign: "right" }}>
                                    <label
                                      style={{
                                        color: "#FFFFFF",
                                        fontWeight: "bold",
                                        fontSize: "22px",
                                      }}
                                    >
                                      &nbsp;&#8377;&nbsp;
                                      {cart.total !== undefined ? parseFloat(cart.total).toFixed(2) : "0.00"}
                                    </label>
                                  </Col>
                                </Row>
                              </Button>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col span={3}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: "10px",
                      }}
                    >
                      <Row>
                        <Col span={24}>
                          <Tooltip title="Edit Qty">
                            <button className="productSpecChange" onClick={() => (selectedRowKeys.length > 0 ? setIsQtyUpdate(true) : "")}>
                              <img src={Quantity} alt="editqty" width={30} height={30} />
                            </button>
                          </Tooltip>
                          <Tooltip title="Remove Product">
                            <button className="productSpecChange" onClick={() => (selectedRowKeys.length > 0 ? deleteProduct(selectedProductInCart) : "")}>
                              <img src={CancelOrder} alt="remove" width={30} height={30} />
                            </button>
                          </Tooltip>
                          <Tooltip title="Decrease Qty">
                            <button className="productSpecChange" onClick={() => (selectedRowKeys.length > 0 && parseFloat(selectedProductInCart.weight) > 1 ? decreaseProductQty(selectedProductInCart) : "")}>
                              <img src={DecrementIcon} alt="decreaseqty" width={30} />
                            </button>
                          </Tooltip>
                          <Tooltip title="Increase Qty">
                            <button className="productSpecChange" onClick={() => (selectedRowKeys.length > 0 ? increaseProductQty(selectedProductInCart) : "")}>
                              <img src={IncrementIcon} alt="increment" width={30} height={30} />
                            </button>
                          </Tooltip>
                          <Tooltip title="Delete Receipt">
                            <button className="productSpecChange" onClick={() => (cart.items.length > 0 ? deleteCart() : "")}>
                              <img src={DeleteCart} alt="delete cart" width={30} height={30} />
                            </button>
                          </Tooltip>
                          <Tooltip title="Order History">
                            <button className="productSpecChange" onClick={() => showOrderHistory()}>
                              <img src={Invoice} alt="invoice" width={30} height={30} />
                            </button>
                          </Tooltip>
                          <Tooltip title="Parked Receipts">
                            <button className="productSpecChange" onClick={() => (parkedList.length > 0 ? openDisplayParkedBillModal() : "")}>
                              <Badge count={parkedList.length || 0} showZero offset={[0, 30]} style={{ backgroundColor: "#607D8B" }}>
                                <img src={onHold} alt="parkedBill" width={35} height={40} />
                              </Badge>
                            </button>
                          </Tooltip>
                          <Tooltip title="Dashboard">
                            <button className="productSpecChange" onClick={confirmDiscardCart}>
                              <img src={Home} alt="dashboard" width={40} height={40} />
                            </button>
                          </Tooltip>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                bodyStyle={{
                  padding: isProductsFilter ? "10px 0px 10px 10px" : "10px",
                }}
                style={{
                  background: "white",
                  height: "auto",
                  minHeight: "93.6vh",
                  margin: "1%",
                  marginLeft: "0.5%",
                  marginBottom: "0px",
                }}
              >
                <Row gutter={[0, 0]}>
                  <Col span={isProductsFilter ? 17 : 24}>
                    <Row gutter={[0, 0]}>
                      <Col className="productsSearchCol" span={!isProductsFilter && isSearchProducts ? 22 : 24}>
                        <Input
                          ref={productSearchInputRef}
                          size="large"
                          onChange={(e) => {
                            e.target.value === "" ? clearProductSearchResults() : setProductSearchInput(e.target.value);
                          }}
                          value={productSearchInput}
                          onFocus={() => setIsSearchProducts(true)}
                          prefix={isSearchProducts ? <ArrowLeftOutlined className="backArrowOrderHistory" onClick={() => closeProductPanel()} /> : null}
                          suffix={productSearchInput.length > 0 ? <CloseSquareFilled className="productSearchSuffix" onClick={() => clearProductSearchResults("")} /> : <span className="productSearchSuffix" />}
                          onPressEnter={getSearchedProducts}
                          addonAfter={<SearchOutlined onClick={getSearchedProducts} />}
                        />
                      </Col>
                      <Col
                        style={{
                          display: !isProductsFilter && isSearchProducts ? "block" : "none",
                        }}
                        span={2}
                      >
                        <Button className="productsFilterBtn" onClick={() => setIsProductsFilter(true)}>
                          <img src={FilterIcn} alt="filter" height={25} width={25} />
                        </Button>
                      </Col>
                    </Row>
                    <br />
                    <Card
                      bodyStyle={{
                        padding: 8,
                        height: "80vh",
                        background: "#F9F9F9",
                      }}
                      className="cardShadow card-radius"
                    >
                      {isQtyUpdate ? <QuantityUpdate selectedProductInCart={selectedProductInCart} addProduct={addProduct} setIsQtyUpdate={setIsQtyUpdate} /> : isSearchProducts ? <ProductPanel checkIsManualWeight={checkIsManualWeight} setIsSearchProducts={setIsSearchProducts} getMoreProducts={getMoreProducts} productsList={productsList} isProductsFilter={isProductsFilter} getSearchedProducts={getSearchedProducts} /> : <AddProduct checkIsManualWeight={checkIsManualWeight} openPaymentModal={openPaymentModal} parkBill={parkBill} />}
                    </Card>
                  </Col>
                  <Col className="categoryFiltersCol" style={{}} span={isProductsFilter ? 7 : 0}>
                    <Menu defaultSelectedKeys={["itemAllProducts"]} mode="inline">
                      <MenuItem
                        style={{
                          backgroundColor: "#E2E8EC",
                          color: "#374957",
                          paddingRight: 0,
                        }}
                      >
                        FILTERS
                        <span style={{ float: "right", color: "#F30019" }}>
                          <CloseOutlined onClick={() => setIsProductsFilter(false)} />
                        </span>
                      </MenuItem>
                      <SubMenu key="item01" icon={<ShoppingCartOutlined />} title="Category">
                        <MenuItem
                          key={"itemAllProducts"}
                          onClick={() => {
                            selectProductCategory("allProducts");
                          }}
                        >
                          All Products
                        </MenuItem>
                        {productCategories.map((item, index) => (
                          <MenuItem
                            key={`item${index + 1}`}
                            onClick={() => {
                              selectProductCategory(item.mProductCategoryId);
                            }}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </SubMenu>
                    </Menu>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>

        {/* CUSTOMER SEARCH MODAL BEGIN*/}
        <Modal visible={displayCustomerSearch} onCancel={closeCustomerSearch} closable={false} footer={null} width="40%">
          <Row>
            <Radio.Group
              size="large"
              onChange={(e) => {
                setCustomerSearchType(e.target.value);
              }}
              value={customerSearchType}
            >
              <Radio value="searchKey" className="radio-lbl-cus" disabled={false}>
                Search By Customer
              </Radio>
              <Radio value="mobile" className="radio-lbl-ph">
                Search By Mobile Number
              </Radio>
            </Radio.Group>
          </Row>
          <Row gutter={[0, 22]}>
            <Input
              placeholder="Search"
              size="large"
              className="customer-search-input"
              suffix={<SearchOutlined onClick={handleCustomerSearch} style={{ fontSize: 26, color: "#37474F" }} />}
              value={customerSearchInput}
              onPressEnter={handleCustomerSearch}
              onChange={(e) => {
                e.target.value === ""
                  ? (() => {
                      setCustomerSearchResults();
                      setCustomerSearchInput(e.target.value);
                    })()
                  : setCustomerSearchInput(e.target.value);
              }}
            />
          </Row>
          <Row gutter={[2, 0]}>
            <Col span={24}>
              {customerSearchResults === undefined ? (
                <div className="left">
                  <Card bordered className="cardShadow card-radius customerSearchList" />
                </div>
              ) : customerSearchResults !== undefined && customerSearchResults.length > 0 ? (
                <div className="left">
                  <Card bordered className="cardShadow card-radius customerSearchList" bodyStyle={{ padding: 10 }}>
                    {customerSearchResults.map((customerSearchResult, index) => (
                      <Card key={`${index}`} className="cardShadow card-radius customerDetailsCard" bodyStyle={{ padding: 10 }}>
                        <Row>
                          <Col span={22} onClick={() => selectCustomer(index)}>
                            <p className="customerName">{customerSearchResult.name}</p>
                          </Col>
                          <Col span={2}>
                            <EditFilled className="dropdownCircleIcon" onClick={() => showEditOldCustomerFields(customerSearchResult)} />
                          </Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }} onClick={() => selectCustomer(index)}>
                          <Col span={24}>
                            <p className="customerStatus">{customerSearchResult.mobileNo}</p>
                          </Col>
                        </Row>
                      </Card>
                    ))}
                  </Card>
                </div>
              ) : (
                <div className="left">
                  <Card bordered className="cardShadow card-radius customerSearchList">
                    <Row>
                      <p className="noResultsText">No results found</p>
                    </Row>
                    <Row>
                      <p className="addNewCustomer" onClick={showAddNewCustomerFields}>
                        + Add as a new customer
                      </p>
                    </Row>
                  </Card>
                </div>
              )}
            </Col>
          </Row>
        </Modal>
        {/* CUSTOMER SEARCH MODAL END*/}

        {/* ADD NEW CUSTOMER MODAL BEGIN*/}
        <Modal
          title={
            <div style={{ paddingTop: 10 }}>
              <Row>
                <Col span={2}>
                  <ArrowLeftOutlined
                    style={{ color: "#000000" }}
                    onClick={() => {
                      setDisplayCustomerSearch(true);
                      setDisplayAddNewCustomer(false);
                      setCustomerSearchInput("");
                      setCustomerSearchResults();
                    }}
                  />
                </Col>
                <Col span={22}>
                  <p style={{ marginBottom: 0 }}>Add New Customer</p>
                </Col>
              </Row>
            </div>
          }
          visible={displayAddNewCustomer}
          onCancel={null}
          footer={null}
          width="40%"
          closable={false}
          bodyStyle={{ padding: 20 }}
        >
          {displayAddNewCustomer ? (
            <Form key={"addCustomer"} form={form} name="addCustomer" onFinish={addNewCustomer}>
              <Row>
                <Col span={12}>
                  <label className="cutomerDetailsField">Name</label>
                  <Form.Item name="name" rules={[{ required: true, message: "Please Input Name" }]}>
                    <Input placeholder="Enter Name" className="cutomerDetailsInput" />
                  </Form.Item>
                </Col>
                <Col span={12} className="customerDetailsSec">
                  <label className="cutomerDetailsField">Mobile Number</label>
                  <Form.Item name="mobile" rules={[{ required: true, pattern: new RegExp(/^[6-9][0-9]{9}$/), message: "Please Input Valid Mobile Number" }]}>
                    <Input placeholder="Enter Mobile Number" className="cutomerDetailsInput" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  {/* <label className="cutomerDetailsField">Date of Birth</label>
                <Form.Item name="dob" rules={[{ required: true, message: 'Please Input Date of Birth' }]}>
                  <DatePicker
                    placeholder="DD/MM/YYYY"
                    className="cutomerDobInput"
                    format="DD/MM/YYYY"
                  />
                </Form.Item> */}
                  <label className="cutomerDetailsField">Email</label>
                  <Form.Item name="email" rules={[{ required: false, pattern: new RegExp(/\S+@\S+\.\S+/), message: "Please Input Valid Email" }]}>
                    <Input placeholder="Enter Email" className="cutomerDetailsInput" />
                  </Form.Item>
                </Col>
                <Col span={12} className="customerDetailsSec">
                  <label className="cutomerDetailsField">Pincode</label>
                  <Form.Item name="pincode" rules={[{ required: false, pattern: new RegExp(/\d+/g), message: "Please Input Pincode" }]}>
                    <Input placeholder="Enter Pincode" className="cutomerDetailsInput" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="customerDetailsBtns">
                <Button
                  size="large"
                  className="customerDetailsCancelBtn"
                  onClick={() => {
                    form.resetFields();
                    closeCustomerSearch();
                    setDisplayAddNewCustomer(false);
                  }}
                >
                  Cancel
                </Button>
                <Button size="large" className="customerDetailsSubmitBtnBtn" htmlType="submit">
                  Submit
                </Button>
              </Row>
            </Form>
          ) : (
            ""
          )}
        </Modal>
        {/* ADD NEW CUSTOMER MODAL END*/}

        {/* EDIT CUSTOMER MODAL BEGIN*/}
        <Modal
          title={
            <div style={{ paddingTop: 10 }}>
              <Row>
                <Col span={2}>
                  <ArrowLeftOutlined
                    style={{ color: "#000000" }}
                    onClick={() => {
                      setDisplayCustomerSearch(true);
                      setDisplayEditOldCustomer(false);
                    }}
                  />
                </Col>
                <Col span={22}>
                  <p style={{ marginBottom: 0 }}>Edit Customer</p>
                </Col>
              </Row>
            </div>
          }
          visible={displayEditOldCustomer}
          onCancel={null}
          footer={null}
          width="40%"
          closable={false}
          bodyStyle={{ padding: 20 }}
        >
          {displayEditOldCustomer ? (
            <Form key={"editCustomer"} form={form} name="editCustomer" onFinish={editOldCustomer}>
              <Row>
                <Col span={12}>
                  <label className="cutomerDetailsField">Name</label>
                  <Form.Item name="editName" rules={[{ required: true, message: "Please Input Name" }]}>
                    <Input placeholder="Enter Name" className="cutomerDetailsInput" />
                  </Form.Item>
                </Col>
                <Col span={12} className="customerDetailsSec">
                  <label className="cutomerDetailsField">Mobile Number</label>
                  <Form.Item name="editMobile" rules={[{ required: true, pattern: new RegExp(/^[6-9][0-9]{9}$/), message: "Please Input Valid Mobile Number" }]}>
                    <Input placeholder="Enter Mobile Number" className="cutomerDetailsInput" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  {/* <label className="cutomerDetailsField">Date of Birth</label>
                <Form.Item name="editDob" rules={[{ required: true, message: 'Please Input Date of Birth' }]}>
                  <DatePicker
                    placeholder="DD/MM/YYYY"
                    className="cutomerDobInput"
                    format="DD/MM/YYYY"
                  />
                </Form.Item> */}
                  <label className="cutomerDetailsField">Email</label>
                  <Form.Item name="editEmail" rules={[{ required: false, pattern: new RegExp(/\S+@\S+\.\S+/), message: "Please Input Valid Email" }]}>
                    <Input placeholder="Enter Email" className="cutomerDetailsInput" />
                  </Form.Item>
                </Col>
                <Col span={12} className="customerDetailsSec">
                  <label className="cutomerDetailsField">Pincode</label>
                  <Form.Item name="editPincode" rules={[{ required: false, pattern: new RegExp(/\d+/g), message: "Please Input Pincode" }]}>
                    <Input placeholder="Enter Pincode" className="cutomerDetailsInput" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="customerDetailsBtns">
                <Button
                  size="large"
                  className="customerDetailsCancelBtn"
                  onClick={() => {
                    form.resetFields();
                    closeCustomerSearch();
                    setDisplayEditOldCustomer(false);
                  }}
                >
                  Cancel
                </Button>
                <Button size="large" className="customerDetailsSubmitBtnBtn" htmlType="submit">
                  Submit
                </Button>
              </Row>
            </Form>
          ) : (
            ""
          )}
        </Modal>
        {/* EDIT CUSTOMER MODAL END*/}

        {/* ORDER HISTORY MODAL BEGIN*/}
        <Modal visible={displayOrderHistory} closable={false} footer={null} width="42%" bodyStyle={{ padding: "20px 15px 15px" }}>
          <Row>
            <Col span={10}>
              <ArrowLeftOutlined className="backArrowOrderHistory" onClick={() => setDisplayOrderHistory(false)} />
            </Col>
            <Col span={14}>
              <p className="orderHistoryModalTitle">Order History</p>
            </Col>
          </Row>
          <Row>
            <Col span={11}>
              <Select defaultValue="orderDocumentNo" onChange={changeOrderHistorySearchType} suffixIcon={<CaretDownOutlined />} style={{ width: "100%" }} placeholder="Attributes" className="orderHistoryAttribute">
                <Option value="orderDocumentNo">Document</Option>
                <Option value="orderCustomerSearchKey">Customer SearchKey</Option>
              </Select>
            </Col>
            <Col span={13}>
              <Input
                placeholder="Search"
                size="large"
                allowClear={false}
                className="orderHistorySearchInput"
                onPressEnter={searchOrderHistory}
                onChange={(e) => {
                  e.target.value === "" ? showOrderHistory() : setOrderHistoryInput(e.target.value);
                }}
                suffix={<SearchOutlined onClick={searchOrderHistory} style={{ fontSize: 26, color: "#37474F" }} />}
              />
            </Col>
          </Row>
          <Row>
            <Tabs defaultActiveKey="1" centered className="orderHistoryTabs">
              <TabPane tab="All" key="1">
                <Row>
                  <Card className="cardShadow card-radius orderHistoryCard" bodyStyle={{ padding: 10 }}>
                    <Scrollbars autoHide style={{ minHeight: "53vh" }}>
                      {orderHistoryDetails.map((item) => (
                        <Card key={item.documentno} className="cardShadow card-radius oderHistoryDetailsCard" bodyStyle={{ padding: 10 }}>
                          <Row>
                            <Col span={12}>
                              <p className="orderDate">{item.orderTime}</p>
                            </Col>
                            <Col span={10}>
                              <p className="orderCustomerName">{item.customer.name}</p>
                            </Col>
                            <Col span={2}>
                              <img className="dropdownCircleIcon" src={DropdownCircle} alt="" width={15} height={15} onClick={() => showOrderHistoryLine(item.sOrderID)} />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={12}>
                              <p className="orderNum">{item.documentno}</p>
                            </Col>
                            <Col span={10}>
                              <p className="orderStatus">{item.isSynced ? "Success" : "Pending"}</p>
                            </Col>
                            <Col span={2}></Col>
                          </Row>
                          {item.sOrderID === selectedOrderHistoryLine ? (
                            <Row>
                              <table className="orderDetailsTable">
                                <tr className="orderDetailsTableHeader">
                                  <th>Description</th>
                                  <th className="orderDetailsTableTh">Qty</th>
                                  <th className="orderDetailsTableTh">Unit Price</th>
                                  <th className="orderDetailsTableTh">Net Total</th>
                                </tr>
                                {item.items.map((subItem, index) => (
                                  <tr key={`${subItem.name}-${index}`} className="orderDetailsData">
                                    <td className="orderDetailsDesc">{subItem.name}</td>
                                    <td>{subItem.weight}</td>
                                    <td>{parseFloat(subItem.salePrice).toFixed(2)}</td>
                                    <td>{parseFloat(subItem.nettotal).toFixed(2)}</td>
                                  </tr>
                                ))}
                              </table>
                              <Divider />
                              <Row className="orderDetailsTaxes">
                                <Col span={12}>
                                  <p>Taxes</p>
                                </Col>
                                <Col span={12}>
                                  <p style={{ float: "right" }}>{parseFloat(item.tax).toFixed(2)}</p>
                                </Col>
                              </Row>
                              <Row className="orderDetailsTotal">
                                <Col span={12}>
                                  <p>Total</p>
                                </Col>
                                <Col span={12}>
                                  <p style={{ float: "right" }}>{parseFloat(item.total).toFixed(2)}</p>
                                </Col>
                              </Row>
                              <Row className="orderDetailsButtons">
                                <Col span={12}>
                                  <Button size="large" className="rePrintBtn" onClick={() => (isPrintModeXML ? xmlPrint(item, "Duplicate Copy") : htmlPrint(item, "Duplicate Copy"))}>
                                    Re-Print
                                  </Button>
                                </Col>
                                <Col span={12}>
                                  <Button size="large" className="returnBtn">
                                    Return
                                  </Button>
                                </Col>
                              </Row>
                            </Row>
                          ) : (
                            ""
                          )}
                        </Card>
                      ))}
                    </Scrollbars>
                  </Card>
                </Row>
              </TabPane>
              <TabPane tab="Sucess" key="2">
                <Row>
                  <Card className="cardShadow card-radius orderHistoryCard" bodyStyle={{ padding: 10 }}>
                    <Scrollbars autoHide style={{ minHeight: "53vh" }}>
                      {orderHistoryDetails.map((item) =>
                        item.isSynced ? (
                          <Card key={item.documentno} className="cardShadow card-radius oderHistoryDetailsCard" bodyStyle={{ padding: 10 }}>
                            <Row>
                              <Col span={12}>
                                <p className="orderDate">{item.orderTime}</p>
                              </Col>
                              <Col span={10}>
                                <p className="orderCustomerName">{item.customer.name}</p>
                              </Col>
                              <Col span={2}>
                                <img className="dropdownCircleIcon" src={DropdownCircle} alt="" width={15} height={15} onClick={() => showOrderHistoryLine(item.sOrderID)} />
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12}>
                                <p className="orderNum">{item.documentno}</p>
                              </Col>
                              <Col span={10}>
                                <p className="orderStatus">{item.isSynced ? "Success" : "Pending"}</p>
                              </Col>
                              <Col span={2}></Col>
                            </Row>
                            {item.sOrderID === selectedOrderHistoryLine ? (
                              <Row>
                                <table className="orderDetailsTable">
                                  <tr className="orderDetailsTableHeader">
                                    <th>Description</th>
                                    <th>Qty</th>
                                    <th>Unit Price</th>
                                    <th>Net Total</th>
                                  </tr>
                                  {item.items.map((subItem, index) => (
                                    <tr key={`${subItem.name}-${index}`} className="orderDetailsData">
                                      <td className="orderDetailsDesc">{subItem.name}</td>
                                      <td>{subItem.weight}</td>
                                      <td>{subItem.salePrice}</td>
                                      <td>{subItem.nettotal}</td>
                                    </tr>
                                  ))}
                                </table>
                                <Row className="orderDetailsTaxes">
                                  <Col span={12}>
                                    <p>Taxes</p>
                                  </Col>
                                  <Col span={12}>
                                    <p style={{ float: "right" }}>{parseFloat(item.tax).toFixed(2)}</p>
                                  </Col>
                                </Row>
                                <Row className="orderDetailsTotal">
                                  <Col span={12}>
                                    <p>Total</p>
                                  </Col>
                                  <Col span={12}>
                                    <p style={{ float: "right" }}>{parseFloat(item.total).toFixed(2)}</p>
                                  </Col>
                                </Row>
                                <Row className="orderDetailsButtons">
                                  <Col span={12}>
                                    <Button size="large" className="rePrintBtn">
                                      Re-print
                                    </Button>
                                  </Col>
                                  <Col span={12}>
                                    <Button size="large" className="returnBtn">
                                      Return
                                    </Button>
                                  </Col>
                                </Row>
                              </Row>
                            ) : (
                              ""
                            )}
                          </Card>
                        ) : (
                          ""
                        )
                      )}
                    </Scrollbars>
                  </Card>
                </Row>
              </TabPane>
              <TabPane tab="Pending" key="3">
                <Row>
                  <Card className="cardShadow card-radius orderHistoryCard" bodyStyle={{ padding: 10 }}>
                    <Scrollbars autoHide style={{ minHeight: "53vh" }}>
                      <Button
                        style={{ left: "40%", marginBottom: "8px" }}
                        onClick={() => {
                          syncOrders("orderHistory");
                        }}
                      >
                        Sync Now
                      </Button>
                      {orderHistoryDetails.map((item) =>
                        parseInt(item.isSynced) === 0 || parseInt(item.isSynced) === 2 ? (
                          <Card key={item.documentno} className="cardShadow card-radius oderHistoryDetailsCard" bodyStyle={{ padding: 10 }}>
                            <Row>
                              <Col span={12}>
                                <p className="orderDate">{item.orderTime}</p>
                              </Col>
                              <Col span={10}>
                                <p className="orderCustomerName">{item.customer.name}</p>
                              </Col>
                              <Col span={2}>
                                <img className="dropdownCircleIcon" src={DropdownCircle} alt="" width={15} height={15} onClick={() => showOrderHistoryLine(item.sOrderID)} />
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12}>
                                <p className="orderNum">{item.documentno}</p>
                              </Col>
                              <Col span={10}>
                                <p className="orderStatus">{item.isSynced ? "Success" : "Pending"}</p>
                              </Col>
                              <Col span={2}></Col>
                            </Row>
                            {item.sOrderID === selectedOrderHistoryLine ? (
                              <Row>
                                <table className="orderDetailsTable">
                                  <tr className="orderDetailsTableHeader">
                                    <th>Description</th>
                                    <th>Qty</th>
                                    <th>Unit Price</th>
                                    <th>Net Total</th>
                                  </tr>
                                  {item.items.map((subItem, index) => (
                                    <tr key={`${subItem.name}-${index}`} className="orderDetailsData">
                                      <td className="orderDetailsDesc">{subItem.name}</td>
                                      <td>{subItem.weight}</td>
                                      <td>{subItem.salePrice}</td>
                                      <td>{subItem.nettotal}</td>
                                    </tr>
                                  ))}
                                </table>
                                <Row className="orderDetailsTaxes">
                                  <Col span={12}>
                                    <p>Taxes</p>
                                  </Col>
                                  <Col span={12}>
                                    <p style={{ float: "right" }}>{parseFloat(item.tax).toFixed(2)}</p>
                                  </Col>
                                </Row>
                                <Row className="orderDetailsTotal">
                                  <Col span={12}>
                                    <p>Total</p>
                                  </Col>
                                  <Col span={12}>
                                    <p style={{ float: "right" }}>{parseFloat(item.total).toFixed(2)}</p>
                                  </Col>
                                </Row>
                                <Row className="orderDetailsButtons">
                                  <Col span={12}>
                                    <Button size="large" className="rePrintBtn">
                                      Re-print
                                    </Button>
                                  </Col>
                                  <Col span={12}>
                                    <Button size="large" className="returnBtn">
                                      Return
                                    </Button>
                                  </Col>
                                </Row>
                              </Row>
                            ) : (
                              ""
                            )}
                          </Card>
                        ) : (
                          ""
                        )
                      )}
                    </Scrollbars>
                  </Card>
                </Row>
              </TabPane>
            </Tabs>
          </Row>
        </Modal>
        {/* ORDER HISTORY MODAL END*/}

        {/* PARKED BILLS MODAL BEGIN*/}
        <Modal visible={displayParkedBillModal} closable={false} footer={null} width="35%" bodyStyle={{ padding: "20px 15px 15px" }}>
          <Row>
            <Col span={9}>
              <ArrowLeftOutlined className="backArrowOrderHistory" onClick={closeParkedBillModal} />
            </Col>
            <Col span={15}>
              <p className="parkedBillsHistoryModalTitle">Parked Orders</p>
            </Col>
          </Row>
          <Row style={{ padding: "12px" }}>
            <Col span={20}>
              <Input onChange={handleParkedBillSearchInput} onPressEnter={searchParkedBill} value={parkedBillSearchInput} placeholder={`Customer Search Key`} />
            </Col>
            <Col span={4}>
              <Button onClick={searchParkedBill}>Search</Button>
            </Col>
          </Row>
          <Row>
            <Card className="cardShadow card-radius orderHistoryCard" bodyStyle={{ padding: 10 }}>
              <Scrollbars autoHide style={{ minHeight: "53vh" }}>
                {filterdParkedList.map((item, index) => (
                  <Card key={`${index + 1}`} className="cardShadow card-radius oderHistoryDetailsCard" bodyStyle={{ padding: 10 }} onClick={() => selectParkedBill(item)}>
                    <Row>
                      <Col span={12}>
                        <p className="orderDate">{item.parkedTime}</p>
                      </Col>
                      <Col span={12}>
                        <p className="orderCustomerName">{index + 1}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <p className="orderNum">{item.parkedCart.customer.name}</p>
                      </Col>
                      <Col span={12}>
                        <p className="orderStatus">Parked</p>
                      </Col>
                      {/* <Col span={2}>
                        </Col> */}
                    </Row>
                  </Card>
                ))}
              </Scrollbars>
            </Card>
          </Row>
        </Modal>
        {/* PARKED BILLS MODAL END*/}

        {/* PAYMENT MODAL BEGIN*/}
        <Modal visible={paymentModal} closable={false} footer="" width="60%" centered bodyStyle={{ padding: 0 }}>
          <Card bodyStyle={{ padding: "10px" }}>
            <Row gutter={[10, 0]}>
              <Col span={12}>
                <Card className="no-border-card" bodyStyle={{ padding: "10px" }}>
                  <Row>
                    <Col span={24}>
                      <Scrollbars autoHide style={{ height: "auto" }}>
                        {/* <Card
                          className="no-border-card"
                          bodyStyle={{
                            padding: "10px",
                            height: "20vh",
                            backgroundColor: "#F5F5F5",
                            borderRadius: "6px 6px 0px 0px",
                          }}
                        >
                          {cart.payments.map((paymentM) => {
                            return (
                              <Row key={paymentM.name}>
                                <Col span={19}>
                                  {" "}
                                  <span className="paymtName">{paymentM.name}</span>
                                </Col>
                                <Col span={3}>
                                  {" "}
                                  <span className="paymtAmt">{paymentM.amount}</span>
                                </Col>
                                <Col span={2}>
                                  <span style={{ float: "right" }}>
                                    <img src={Delete} onClick={() => requestPayment(paymentM, -paymentM.amount, "allow")} className="delete-icon" alt="" />
                                  </span>
                                </Col>
                              </Row>
                            );
                          })}
                        </Card> */}
                      </Scrollbars>
                    </Col>
                  </Row>
                  {/*  <p className="para-margin" /> */}
                  <Row>
                    <Col span={24}>
                      <Scrollbars autoHide style={{ height: "70vh" }}>
                        {tillDataPaymentMethods.map((pm) => {
                          return (
                            <Card
                              key={pm.finPaymentmethodId}
                              onClick={() => {
                                setSelectedPaymentMethod(pm);
                              }}
                              className="no-border-card"
                              bodyStyle={{ padding: "0px", marginTop: "15px" }}
                            >
                              <Row>
                                <Col span={24}>
                                  <Card className={pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "selectedPaymentType" : "paymt-card"} bodyStyle={{ padding: "8px" }}>
                                    <Row>
                                      <Col span={12}>
                                        <img src={pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? CashLogoWhite : CashLogo} className="wfifty" alt="" /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <span className={pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "selectedPaymentTypeName" : "paymt-name"}>{pm.name}</span>
                                      </Col>
                                      <Col span={12}>
                                        {(() => {
                                          const iN = cart.payments.findIndex((paymentMade) => paymentMade.name === pm.name);
                                          if (iN >= 0) {
                                            return (
                                              <Row>
                                                <Col span={20}>
                                                  <span className={pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "selectedPaymentTypeName" : "paymt-name"} style={{ float: "right" }}>
                                                    {cart.payments[iN].amount}
                                                  </span>
                                                </Col>
                                                <Col span={4}>
                                                  <span style={{ float: "right" }}>
                                                    <img src={Delete} onClick={() => requestPayment(cart.payments[iN], -cart.payments[iN].amount, "allow")} className="delete-icon" alt="" />
                                                  </span>
                                                </Col>
                                              </Row>
                                            );
                                          }
                                        })()}
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                              </Row>
                            </Card>
                          );
                        })}
                      </Scrollbars>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 0 }}>
                    <Button type="dashed" size="large" disabled={cart.mPricingCouponId ? true : false} onClick={() => setCouponModalVisible(true)} className="add-payment-btn-white">
                      <TagsOutlined /> Apply Coupon
                    </Button>
                  </Row>
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  className="no-border-card"
                  bodyStyle={{
                    padding: "10px",
                    /*  backgroundColor: "#F5F5F5",
                    borderRadius: "0px 0px 6px 6px", */
                  }}
                >
                  <Row>
                    <Col offset={12} span={12}>
                      <span className="right-float">
                        <img src={closeIcon} alt="close" onClick={closePaymentModal} />
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col offset={12} span={12}>
                      <br />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      {" "}
                      <span
                        style={{
                          fontSize: "30px",
                          fontWeight: "600",
                          color: "#000000",
                        }}
                      >
                        {parseFloat(cart.total) >= parseFloat(cart.paid) ? "To Pay" : "Change"}
                      </span>
                    </Col>
                    <Col span={12}>
                      <span
                        style={{
                          float: "right",
                          fontWeight: "600",
                          color: "#000000",
                          fontSize: "30px",
                        }}
                      >
                        {parseFloat(cart.total) >= parseFloat(cart.paid) ? parseFloat(cart.total - cart.paid).toFixed(2) : parseFloat(cart.paid - cart.total).toFixed(2)}
                      </span>
                    </Col>
                  </Row>
                </Card>
                <Card className="no-border-card" bodyStyle={{ padding: "10px" }}>
                  <Row>
                    <Col span={24}>
                      <h3 className="topay-entr-amt">{paymentModalLoyalityMessages.inputMessage}</h3>
                    </Col>
                  </Row>
                  <p className="para-margin" />
                  <Row>
                    <Col span={24}>
                      <Input value={amount} onChange={onChangeAmount} onPressEnter={parseFloat(cart.total) > parseFloat(cart.paid) ? () => requestPayment(selectedPaymentMethod, amount) : () => processOrder()} className="topay-input" size="large" />
                    </Col>
                  </Row>
                  <p className="para-margin" />
                  <Row style={{ paddingTop: 10 }}>
                    <Col span={6} className="col-text-align">
                      <button className="amt-dial-btn" onClick={() => handleAmount("1")}>
                        1
                      </button>
                    </Col>
                    <Col span={6} className="col-text-align">
                      <button className="amt-dial-btn" onClick={() => handleAmount("2")}>
                        2
                      </button>
                    </Col>
                    <Col span={6} className="col-text-align">
                      <button className="amt-dial-btn" onClick={() => handleAmount("3")}>
                        3
                      </button>
                    </Col>
                    <Col span={6} className="col-text-align">
                      <button className="amt-dial-btn" onClick={() => handleAmount("x")}>
                        <img src={Backspace} alt="back space" width={30} height={25} />
                      </button>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 15 }}>
                    <Col span={6} className="col-text-align">
                      <button className="amt-dial-btn" onClick={() => handleAmount("4")}>
                        4
                      </button>
                    </Col>
                    <Col span={6} className="col-text-align">
                      <button className="amt-dial-btn" onClick={() => handleAmount("5")}>
                        5
                      </button>
                    </Col>
                    <Col span={6} className="col-text-align" onClick={() => handleAmount("6")}>
                      <button className="amt-dial-btn">6</button>
                    </Col>
                    <Col span={6} className="col-text-align">
                      <button className="amt-dial-btn" onClick={() => handleAmount(".")}>
                        .
                      </button>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 15 }}>
                    <Col span={6} className="col-text-align">
                      <button className="amt-dial-btn" onClick={() => handleAmount("7")}>
                        7
                      </button>
                    </Col>
                    <Col span={6} className="col-text-align">
                      <button className="amt-dial-btn" onClick={() => handleAmount("8")}>
                        8
                      </button>
                    </Col>
                    <Col span={6} className="col-text-align" onClick={() => handleAmount("9")}>
                      <button className="amt-dial-btn">9</button>
                    </Col>
                    <Col span={6} className="col-text-align">
                      <button className="amt-dial-btn" onClick={() => handleAmount("0")}>
                        0
                      </button>
                    </Col>
                  </Row>
                  <p className="para-margin" />
                  <Row style={{ paddingTop: 18 }}>
                    <Button size="large" onClick={parseFloat(cart.total) > parseFloat(cart.paid) ? () => requestPayment(selectedPaymentMethod, amount) : () => processOrder()} className="add-payment-btn">
                      {parseFloat(cart.total) !== 0 ? (parseFloat(cart.total) > parseFloat(cart.paid) ? paymentModalLoyalityMessages.buttonText : "Order") : "Payment Not Allowed !"}
                    </Button>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Card>
        </Modal>
        {/* PAYMENT MODAL END*/}

        {/* ORDER TYPE SELECTION MODAL START*/}
        <Modal visible={displaySetOrderType} closable={false} footer={null} width="35%" bodyStyle={{ padding: "20px 15px 15px" }}>
          <Row>
            <Col span={24}>
              <p style={{ textAlign: "center" }} className="parkedBillsHistoryModalTitle">
                Order Type
              </p>
            </Col>
          </Row>
          <Row>
            <Card className="cardShadow card-radius orderHistoryCard" bodyStyle={{ padding: 10 }}>
              <Scrollbars autoHide style={{ minHeight: "53vh" }}>
                {posSaleTypes.map((item, index) => (
                  <Card key={`${item.name}-${index}`} className="cardShadow card-radius oderHistoryDetailsCard" bodyStyle={{ padding: 20 }} onClick={() => changeOrderType(item)}>
                    <Row>
                      <Col span={24}>
                        <p style={{ textAlign: "center", fontSize: "14px" }} className="orderNum">
                          {item.cwrSaletype.name}
                        </p>
                      </Col>
                    </Row>
                  </Card>
                ))}
              </Scrollbars>
            </Card>
          </Row>
        </Modal>
        {/* ORDER TYPE SELECTION MODAL END*/}

        {/* OFFER SELECTION MODAL BEGIN*/}
        <Modal visible={displayOfferProductSelectiton} footer={null} closable={false} bodyStyle={{ padding: 0 }} width="30%">
          <Row style={{ padding: "15px 20px" }}>
            <Col span={24}>
              <h3 style={{ textAlign: "center" }}>Select an Offer Product</h3>
            </Col>
          </Row>
          {offerProductsList
            ? offerProductsList.map((data, index) => (
                <Row key={`${data.name}-${index}`} style={{ padding: "10px 20px" }}>
                  <Col span={24}>
                    <Button size="large" onClick={() => selectOfferProduct(data)} style={{ width: "100%" }}>
                      {data.name}
                    </Button>
                  </Col>
                </Row>
              ))
            : ""}
        </Modal>
        {/* OFFER SELECTION MODAL END*/}

        {/* BATCH SELECTION MODAL START*/}
        <Modal visible={displayBatchSelection} closable={true} footer={null} onCancel={() => setDisplayBatchSelection(false)} width="35%" bodyStyle={{ padding: "20px 15px 15px" }}>
          <Row>
            <Col span={24}>
              <p style={{ textAlign: "center" }} className="parkedBillsHistoryModalTitle">
                Product Batch
              </p>
            </Col>
          </Row>
          <Row>
            <Card className="cardShadow card-radius orderHistoryCard" bodyStyle={{ padding: 10 }}>
              <Scrollbars autoHide style={{ minHeight: "53vh" }}>
                {batchSetAvailable.map((item, index) => (
                  <Card key={`${item.batchno}-${index}`} className="cardShadow card-radius oderHistoryDetailsCard" bodyStyle={{ padding: 10 }} onClick={() => selectProductToCart(item)}>
                    <Row>
                      <Col span={12}>
                        <p className="orderDate">Batch</p>
                      </Col>
                      <Col span={12}>
                        <p className="orderCustomerName">Price</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <p className="orderNum">{item.batchno}</p>
                      </Col>
                      <Col span={12}>
                        <p className="orderStatus">&#8377; {parseFloat(item.salePrice).toFixed(2)}</p>
                      </Col>
                    </Row>
                  </Card>
                ))}
              </Scrollbars>
            </Card>
          </Row>
        </Modal>
        {/* BATCH SELECTION MODAL END*/}

        {/* PRODUCT WEIGHT MODAL START */}
        <Modal visible={displayManualQtyWeightInput} closable={true} onCancel={() => setDisplayManualQtyWeightInput(false)} footer={null} width="75%" bodyStyle={{ padding: 20 }}>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Card bodyStyle={{ padding: 0 }}>
                <img onError={setDefaultImage} style={{ Width: "150px", height: "150px" }} src={currentWeightSelectedProduct.imageurl} alt="" />
              </Card>
            </Col>
            {/* {productInfo.map((item) => ( */}
            <Col span={12}>
              <Row>
                <h3 style={{ marginBottom: 5 }} className="prod-head">
                  {currentWeightSelectedProduct.name}
                </h3>
              </Row>
              <Row>
                <p className="prod-descp" style={{ marginBottom: 5 }}>
                  {currentWeightSelectedProduct.description}
                </p>
              </Row>
              <Row>
                <Col span={6}>
                  <p /* className="prod-descp-price-mrp" */ className="prod-descp-price" style={{ marginBottom: 5 }}>
                    MRP: Rs.{currentWeightSelectedProduct.salePrice}
                  </p>
                </Col>
                <Col span={18}>
                  {/* <p className="prod-head" style={{ marginBottom: 5 }}>
                      Rs.{item.discoutPrice}
                    </p> */}
                </Col>
              </Row>
              <Row>
                {/* <p className="prod-descp-price" style={{ marginBottom: 5 }}>
                  You Save: {item.discountPercentage} (inclusive of all taxes)
                </p> */}
              </Row>
              {/* <h3 className="prod-head">Pack Sizes</h3>
                <Scrollbars autoHide style={{ height: "35vh" }}>
                  {item.prodDetails.map((subItem) => (
                    <div>
                      <Card bodyStyle={{ padding: 0 }} className={subItem.selected === true ? "prod-select-div" : "prod-div"}>
                        <Row>
                          <Col span={4} className="prod-col">
                            <Row>
                              <p className="prod-weight">{subItem.prodWeight}</p>
                            </Row>
                            <Row>
                              <p className="prod-pack">Pack</p>
                            </Row>
                          </Col>
                          <Col span={5} className="prod-col">
                            <p className="prod-weight">Rs. {subItem.discoutPrice}</p>
                          </Col>
                          <Col span={7} className="prod-col">
                            <p className="prod-descp-price">
                              MRP: <span className="prod-descp-price-mrp">Rs. {subItem.mrpPrice}</span>
                            </p>
                          </Col>
                          <Col span={5} className="prod-col">
                            <p className="prod-offer">{subItem.discountPercentage} Off</p>
                          </Col>
                          <Col span={3} className={subItem.selected === true ? "prod-selected-col" : ""}>
                            <CheckOutlined className="prod-select" />
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  ))}
                </Scrollbars> */}
              <h3 className="prod-head">Quantity</h3>
              <Row>
                <Col span={12}>
                  <div className="prod-qty">
                    {/* <MinusOutlined
                        className="prod-qty-dec"
                        type="minus"
                        // onClick={() =>
                        //   handleCount("decrement", record, "prodQty", index)
                        // }
                      /> */}
                    <Form.Item className="prod-qty-form">
                      <Input value={productWeightModalInput} className="prod-qty-input" onChange={onProductModalChangeWeight} />
                    </Form.Item>
                    {/* <PlusOutlined
                        className="prod-qty-inc"
                        type="plus"
                        // onClick={() =>
                        //   handleCount("increment", record, "prodQty", index)
                        // }
                      /> */}
                  </div>
                </Col>
                <Col span={12}>
                  <Button type="primary" size="large" className="prod-qty-btn" onClick={addManualWeightToProduct}>
                    Add to cart
                  </Button>
                </Col>
              </Row>
            </Col>
            {/* ))} */}
          </Row>
        </Modal>
        {/* PRODUCT WEIGHT MODAL END */}

        {/* COUPON MODAL START */}
        <Modal visible={couponModalVisible} closable={true} onCancel={closeCouponModal} footer={null} onclosefooter={null} width="35%" bodyStyle={{ padding: "20px 15px 15px" }}>
          <Row style={{ textAlign: "center" }}>Enter Coupon Code</Row>
          <Row style={{ padding: "30px" }}>
            <Col span={20}>
              <Input onPressEnter={checkCoupon} onChange={(e) => setCouponInput(e.target.value)} />
            </Col>
            <Col span={4}>
              <Button onClick={checkCoupon}>Apply</Button>
            </Col>
          </Row>
        </Modal>
        {/* COUPON MODAL END */}

        {/* LOYALITY OTP MODAL START */}
        <Modal visible={loyalityOtpModalVisible} closable={true} onCancel={null} footer={null} onclosefooter={null} width="35%" bodyStyle={{ padding: "20px 15px 15px" }}>
          <Row style={{ textAlign: "center" }}>Enter OTP</Row>
          <Row style={{ padding: "30px" }}>
            <Col span={20}>
              <Input onPressEnter={processOtpInput} onChange={(e) => setLoyaltyPaymentOtp(e.target.value)} />
            </Col>
            <Col span={4}>
              <Button onClick={processOtpInput}>Verify</Button>
            </Col>
          </Row>
        </Modal>
        {/* LOYALITY OTP MODAL END */}
      </Spin>
    </div>
  );
};
// PointOfSale Component End

export default PointOfsale;
