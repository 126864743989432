import React, { useState } from "react";
import { Card, Row, Col, Modal } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { Scrollbars } from "react-custom-scrollbars";
import DefaultProductImage from "../../assets/images/default-product.webp";
import "../style.css";

const ProductPanel = (props) => {
  const [displayBatchSelection, setDisplayBatchSelection] = useState(false);
  const [batchSetAvailable, setBatchSetAvailable] = useState([]);

  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };

  const pickProduct = (obj) => {
    if (obj.batchedProduct === "Y") {
      if (obj.mBatch.length === 1) {
        addDefinedProduct(obj, obj.mBatch[0].upc, obj.mBatch[0].batchno, obj.mBatch[0].mBatchId, obj.mBatch[0].price);
      } else {
        const productSet = [];
        for (let i = 0; i < obj.mBatch.length; i += 1) {
          const batchItem = obj.mBatch[i];
          const productDefined = {
            batchno: batchItem.batchno,
            description: obj.description,
            discount: 0,
            discountName: "",
            imageurl: obj.imageurl,
            isDecimal: obj.isDecimal,
            isManualQty: obj.isManualQty,
            isPromoApplicable: obj.isPromoApplicable,
            isReturn: false,
            mBatchId: batchItem.mBatchId,
            mPricingruleId: null,
            name: obj.name,
            nettotal: 0,
            primaryOrderLine: null,
            productId: obj.mProductId,
            realPrice: batchItem.price,
            returnQty: null,
            salePrice: batchItem.price,
            stock: obj.onhandQty,
            tax: obj.cTaxId,
            taxAmount: 0,
            taxRate: obj.taxRate,
            uom: obj.csUomId,
            uom_name: obj.uomName,
            upc: batchItem.upc,
            value: obj.value,
            weight: 0,
            shortDescription: obj.shortDescription,
            hsncode: obj.hsncode,
            csBunitId: obj.csBunitId,
            mProductCategoryId: obj.mProductCategoryId,
            productManufacturerId: obj.productManufacturerId,
            productBrandId: obj.productBrandId,
          };
          productSet.push(productDefined);
        }
        setBatchSetAvailable([...productSet]);
        setDisplayBatchSelection(true);
      }
    } else {
      addDefinedProduct(obj, obj.upc, null, null, obj.sunitprice);
    }
  };

  const addDefinedProduct = (productObj, upc, batchno, mBatchId, price) => {
    const productDefined = {
      batchno: batchno,
      description: productObj.description,
      discount: 0,
      discountName: "",
      imageurl: productObj.imageurl,
      isDecimal: productObj.isDecimal,
      isManualQty: productObj.isManualQty,
      isPromoApplicable: productObj.isPromoApplicable,
      isReturn: false,
      mBatchId: mBatchId,
      mPricingruleId: null,
      name: productObj.name,
      nettotal: 0,
      primaryOrderLine: null,
      productId: productObj.mProductId,
      realPrice: price,
      returnQty: null,
      salePrice: price,
      stock: productObj.onhandQty,
      tax: productObj.cTaxId,
      taxAmount: 0,
      taxRate: productObj.taxRate,
      uom: productObj.csUomId,
      uom_name: productObj.uomName,
      upc: upc,
      value: productObj.value,
      weight: 0,
      shortDescription: productObj.shortDescription,
      hsncode: productObj.hsncode,
      csBunitId: productObj.csBunitId,
      mProductCategoryId: productObj.mProductCategoryId,
      productManufacturerId: productObj.productManufacturerId,
      productBrandId: productObj.productBrandId,
    };
    props.checkIsManualWeight(productDefined);
  };

  const selectProductToCart = (data) => {
    props.checkIsManualWeight(data);
    setDisplayBatchSelection(false);
  };

  const col = [];
  const productsCount = props.productsList.length;

  for (let i = 0; i < productsCount; i += 1) {
    const obj = props.productsList[i];
    col.push(
      <Col key={`${obj.mProductId}${obj.name}`} span={props.isProductsFilter ? 12 : 8}>
        <Card onClick={() => pickProduct(obj)} bodyStyle={{ padding: 30 }} style={{ width: props.productsList.length === 1 ? "20vh" : "95%", height: "95%", background: "#FFFFFF", borderRadius: "5px", border: "none" }}>
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <img onError={setDefaultImage} className="wfifty hfifty" style={{ Width: "120px", height: "120px" }} src={obj.imageurl} alt="" />
            </Col>
          </Row>
          <Row style={{ paddingTop: "20px" }}>
            <Col span={24}>
              <label style={{ fontWeight: "600", fontSize: "18px", color: "030D1C" }}>
                &#8377;&nbsp;<span>{obj.sunitprice}</span>
              </label>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <label className="prod-name">{obj["name"]}</label>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  }
  return (
    <React.Fragment>
      <Scrollbars autoHide style={{ height: "75.5vh" }}>
        <Row>
          <InfiniteScroll
            dataLength={productsCount}
            next={() => props.getMoreProducts()}
            hasMore={true}
            endMessage={
              <p className="center">
                <b>Yay! you have seen it all</b>
              </p>
            }
            style={{ overflowX: "hidden" }}
          >
            <Row>{col.map((element) => element)}</Row>
          </InfiniteScroll>
        </Row>
      </Scrollbars>

      {/* BATCH SELECTION MODAL START*/}
      <Modal visible={displayBatchSelection} closable={true} footer={null} onCancel={() => setDisplayBatchSelection(false)} width="35%" bodyStyle={{ padding: "20px 15px 15px" }}>
        <Row>
          <Col span={24}>
            <p style={{ textAlign: "center" }} className="parkedBillsHistoryModalTitle">
              Product Batch
            </p>
          </Col>
        </Row>
        <Row>
          <Card className="cardShadow card-radius orderHistoryCard" bodyStyle={{ padding: 10 }}>
            <Scrollbars autoHide style={{ minHeight: "53vh" }}>
              {batchSetAvailable.map((item) => (
                <Card className="cardShadow card-radius oderHistoryDetailsCard" bodyStyle={{ padding: 10 }} onClick={() => selectProductToCart(item)}>
                  <Row>
                    <Col span={12}>
                      <p className="orderDate">Batch</p>
                    </Col>
                    <Col span={12}>
                      <p className="orderCustomerName">Price</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <p className="orderNum">{item.batchno}</p>
                    </Col>
                    <Col span={12}>
                      <p className="orderStatus">&#8377; {parseFloat(item.salePrice).toFixed(2)}</p>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Scrollbars>
          </Card>
        </Row>
      </Modal>
      {/* BATCH SELECTION MODAL END*/}
    </React.Fragment>
  );
};

export default ProductPanel;
